import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './App';

// const notIncludesIds = ["sidebar-btn"];

const notIncludesIds = ["sidebar-btn"];
// document.addEventListener('keydown', (event) => {
//   // Check if the pressed key is Enter
//   if (event.key === 'Enter') {
//     event.preventDefault(); // Prevent the default Enter key behavior

//     // Get the nearest parent form of the currently focused element
//     const form = event.target.closest('form');

//     // Proceed only if the event target is within a form
//     if (form) {
//       // Get all focusable elements inside this form
//       const focusableElements = Array.from(
//         form.querySelectorAll('input, select, textarea, button, a[href]')
//       ).filter((el) => !el.disabled && el.tabIndex >= 0);

//       const currentElement = event.target;

//       const currentIndex = focusableElements.indexOf(currentElement);
//       const nextIndex = (currentIndex + 1) % focusableElements.length; // Loop back to first element if at the end

//       const nextElement = focusableElements[nextIndex];
//       const currentElements = focusableElements[currentIndex];

//       if (currentElements?.type === 'button' && !currentElements?.ariaLabel?.includes('date') && !notIncludesIds.includes(currentElement?.id)) {
//         currentElements.click();
//       } else {
//         nextElement.focus(); // Set focus to the next element
//       }
//     }
//   }
// });
document.addEventListener('keydown', (event) => {
  // Check if the pressed key is Enter
  if (event.key === 'Enter') {
    event.preventDefault(); // Prevent the default Enter key behavior

    const currentElement = event.target;
    const form = currentElement.closest('form');

    // Proceed only if the event target is within a form
    if (form) {
      // Get all focusable elements inside this form
      const focusableElements = Array.from(
        form.querySelectorAll('input, select, textarea, button, a[href]')
      ).filter((el) => !el.disabled && el.tabIndex >= 0);

      const currentIndex = focusableElements.indexOf(currentElement);
      const nextIndex = (currentIndex + 1) % focusableElements.length; // Loop back to first element if at the end

      const nextElement = focusableElements[nextIndex];

      // Special handling for textarea
      if (currentElement.tagName === 'TEXTAREA') {
        const lines = currentElement.value.split('\n');
        const lastLine = lines[lines.length - 1];

        if (lastLine === '') {
          // If last line is empty, move to next element
          lines.pop(); // Remove the last empty line
          currentElement.value = lines.join('\n'); // Join the remaining lines back into the string
          nextElement.focus();
        } else {
          // If last line is not empty, insert a new line
          const start = currentElement.selectionStart;
          const end = currentElement.selectionEnd;

          currentElement.value = `${currentElement.value.substring(0, start)}\n${currentElement.value.substring(end)}`;
          // eslint-disable-next-line no-multi-assign
          currentElement.selectionStart = currentElement.selectionEnd = start + 1;
        }
      } else if (currentElement.type === 'button' && !currentElement.ariaLabel?.includes('date') && !notIncludesIds.includes(currentElement.id)) {
        currentElement.click();
      } else {
        // Set focus to the next element
        nextElement.focus();
      }
    }
  }
});

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <HashRouter>
      <Suspense>
        <App />
      </Suspense>
    </HashRouter>
  </HelmetProvider>
);
