// @mui
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
// api
import { SplashScreen } from 'src/components/loading-screen';
import useSWR from 'swr';
import { fetcher } from 'src/utils/axios';

// _mock
import { _bookings, _bookingNew, _bookingsOverview, _bookingReview } from 'src/_mock';
// assets
import {
  BookingIllustration,
  CheckInIllustration,
  CheckoutIllustration,
} from 'src/assets/illustrations';
// components
import { useSettingsContext } from 'src/components/settings';
//
import BookingBooked from '../booking-booked';
import BookingNewest from '../booking-newest';
import BookingDetails from '../booking-details';
import BookingAvailable from '../booking-available';
import BookingStatistics from '../booking-statistics';
import BookingTotalIncomes from '../booking-total-incomes';
import BookingWidgetSummary from '../booking-widget-summary';
import BookingCheckInWidgets from '../booking-check-in-widgets';
import BookingCustomerReviews from '../booking-customer-reviews';

// ----------------------------------------------------------------------


const SPACING = 3;

export default function OverviewBookingView() {
  const theme = useTheme();

  const settings = useSettingsContext();

  const { data, error, isLoading } = useSWR('/automobile/bookings/dashboard/', fetcher);

  if (error) return <div>failed to load</div>;

  if (isLoading) return <SplashScreen />;

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={SPACING} disableEqualOverflow>
        {
          data?.data?.chartData && <Grid xs={12}>
            <BookingStatistics
              title="Statistics"
              subheader="(+43% Sold | +12% Canceled) than last year"
              chart={{
                colors: [theme.palette.primary.main, theme.palette.error.light],
                ...data.data.chartData,
              }}
            />
          </Grid>
        }
        <Grid xs={12} md={4}>
          <BookingWidgetSummary
            title="Total Booking"
            total={data?.data?.total || 0}
            icon={<BookingIllustration />}
          />
        </Grid>

        <Grid xs={12} md={4}>
          <BookingWidgetSummary title="Sold" total={data?.data?.sold || 0} icon={<CheckInIllustration />} />
        </Grid>

        <Grid xs={12} md={4}>
          <BookingWidgetSummary title="Canceled" total={data?.data?.canceled || 0} icon={<CheckoutIllustration />} />
        </Grid>

        <Grid container xs={12}>
          <Grid container xs={12} md={8}>
            {/* <Grid xs={12} md={6}>
              <BookingTotalIncomes
                title="Total Incomes"
                total={18765}
                percent={2.6}
                chart={{
                  series: [
                    { x: 2016, y: 111 },
                    { x: 2017, y: 136 },
                    { x: 2018, y: 76 },
                    { x: 2019, y: 108 },
                    { x: 2020, y: 74 },
                    { x: 2021, y: 54 },
                    { x: 2022, y: 57 },
                    { x: 2023, y: 84 },
                  ],
                }}
              />
            </Grid> */}

            <Grid xs={12} md={6}>
              <BookingBooked title="Booked" data={[{
                status: 'Pending',
                quantity: data?.data?.total,
                value: data?.data?.pendingforpaymentPercentage,
              }, {
                status: 'Canceled',
                quantity: data?.data?.canceled,
                value: data?.data?.canceledPercentage
              }, {
                status: 'Sold',
                quantity: data?.data?.sold,
                value: data?.data?.soldPercentage,
              }]} />
            </Grid>

            <Grid xs={12}>
              <BookingCheckInWidgets
                chart={{
                  series: [
                    { label: 'Sold', percent: data?.data?.soldPercentage, total: data?.data?.sold },
                    { label: 'Pending for payment', percent: data?.data?.pendingforpaymentPercentage, total: data?.data?.pedningforpayment },
                  ],
                }}
              />
            </Grid>

          </Grid>

          <Grid xs={12} md={4}>
            <BookingAvailable
              title="Stocks Available"
              subtitle="Stocks"
              chart={{
                series: [
                  { label: 'Sold out', value: data?.data?.sold },
                  { label: 'Available', value: (data?.data?.total || 0) - (data?.data?.sold || 0) },
                ],
              }}
            />

            {/* <BookingCustomerReviews
              title="Customer Reviews"
              subheader={`${_bookingReview.length} Reviews`}
              list={_bookingReview}
              sx={{ mt: SPACING }}
            /> */}
          </Grid>
        </Grid>
        {/* <Grid xs={12}>
          <BookingNewest title="Newest Booking" subheader="12 Booking" list={_bookingNew} />
        </Grid> */}

        {/* <Grid xs={12}>
          <BookingDetails
            title="Booking Details"
            tableData={_bookings}
            tableLabels={[
              { id: 'destination', label: 'Destination' },
              { id: 'customer', label: 'Customer' },
              { id: 'checkIn', label: 'Check In' },
              { id: 'checkOut', label: 'Check Out' },
              { id: 'status', label: 'Status' },
              { id: '' },
            ]}
          />
        </Grid> */}
      </Grid>
    </Container>
  );
}
