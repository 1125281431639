import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Dialog, DialogActions } from '@mui/material';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { fMoney, formatDate } from 'src/_mock/constant_funcation';
import { CASH_DEBIT, INVOI_TYPE, Tax_BilSupply } from 'src/_mock/constant';
import SaleInvoicePDF from './sale-invoice-pdf';

export default function UserTableRow({
  setEditRow,
  tableData,
  row,
  index,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  table,
  getAll,
  quickEdit,
  quickView,
  permission,
  productcategoryobj,
}) {
  const confirm = useBoolean();
  const view = useBoolean();
  const popover = usePopover();
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{index}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>{row?.identity ? row.identity : '-'}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>{row?.challan?.identity ? row.challan?.identity : '-'}</TableCell>
        <TableCell>{row?.dt ? formatDate(row.dt) : '-'}</TableCell>
        <TableCell>{row?.challan?.challandate ? formatDate(row.challan.challandate) : '-'}</TableCell>
        <TableCell>{row?.pcategoryId ? productcategoryobj[row.pcategoryId] : '-'}</TableCell>
        <TableCell>{INVOI_TYPE?.find((x) => x.id === row?.t_type)?.name || '-'}</TableCell>
        <TableCell>{row?.stockdetails.length ? row?.stockdetails.map(x => x.chassisNo)?.join(', ') : '-'}</TableCell>
        <TableCell>{row.entry_details.find((x) => x.order === 1)?.account?.identity || '-'}</TableCell>
        <TableCell>{row.entry_details.find((x) => x.order === 1)?.account?.name || '-'}</TableCell>
        {/* <TableCell>{row.entry_details.find((x) => x.order === 2)?.account?.name || '-'}</TableCell>
        <TableCell>
          {row?.tax_bi_Id ? Tax_BilSupply.find((x) => x.id === row.tax_bi_Id)?.name : '-'}
        </TableCell> */}
        <TableCell sx={{ textAlign: 'right' }}>
          {row?.amount ? fMoney.format(row?.amount, { symbol: '₹ ' }) : '-'}
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="View">
            <IconButton onClick={view.onTrue}>
              <Iconify icon="solar:eye-bold" />
            </IconButton>
          </Tooltip>
          {permission?.u && (
            <Tooltip title="Quick Edit" placement="top" arrow>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={() => {
                  setEditRow(row);
                  quickEdit.setValue(true);
                }}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
          )}

          {(permission?.u || permission?.d) && (
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <Dialog fullScreen open={view.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={view.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <SaleInvoicePDF invoice={row} />

          </Box>
        </Box>
      </Dialog>
      {/* <UserQuickEditForm
        tableData={tableData}
        row={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        table={table}
        getAll={getAll}
      /> */}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.d && (
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        )}

        {permission?.u && (
          <MenuItem
            onClick={() => {
              setEditRow(row);
              quickEdit.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}
        {permission?.u && (
          <MenuItem
            onClick={() => {
              setEditRow(row);
              quickView.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="zondicons:view-show" />
            View
          </MenuItem>
        )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  quickEdit: PropTypes.any,
  quickView: PropTypes.any,
  setEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  table: PropTypes.object,
  permission: PropTypes.object,
  getAll: PropTypes.func,
  tableData: PropTypes.array,
  productcategoryobj: PropTypes.object,
};
