import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
import { fetcher } from 'src/utils/axios';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import { padWithZeros } from 'src/_mock/constant';

// ----------------------------------------------------------------------

Font.register({
  family: 'Verdana',
  fonts: [{ src: '/fonts/Verdana/Verdana.ttf' }, { src: '/fonts/Verdana/Verdana_Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        page: {
          padding: 20,
          fontFamily: 'Verdana',
        },
        section: {
          margin: 10,
          padding: 10,
        },
        header: {
          textAlign: 'center',
          marginBottom: 10,
          height: '80px'
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 20,
          paddingBottom: 10,
          margin: 'auto',
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        boldText: {
          fontWeight: 'bold',
        },
        infoRow: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        },
        tableHeader: {
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid black',
          paddingBottom: 5,
          marginBottom: 5,
        },
        table: {
          border: '1px solid black',
          paddingBottom: 5,
          marginBottom: 5
        },
        tableRow: {
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
        },
        tableCol: {
          width: '20%',
        },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function ChallanPDF({ invoice }) {
  const { id } = invoice || {};  // Ensure invoice exists
  const { user } = useContext(AuthContext);
  const company = user?.companies.find((x) => x.id === user?.companyId);
  const branch = user?.branches?.find((x) => x.id === user?.branchId)
  const [entry, setEntry] = useState(null);

  const GETBYID = async (id) => {
    if (!id) return;  // Ensure ID is available before making the request
    try {
      const URL = `automobile/challans/${id}?status=true&`;
      const { data } = await fetcher(URL);
      if (data) setEntry(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (invoice?.id) {
      GETBYID(invoice.id);
    }
  }, [invoice]);

  const vehicleInformation = entry && 'cha_details' in entry && Array.isArray(entry.cha_details) && entry.cha_details.length && entry.cha_details[0];
  const styles = useStyles();
  
  // Prevent rendering if entry or required data is not loaded yet
  if (!entry || !company) {
    return <>Loading...</>;
  }


  const terms = [
    "Goods ones sold will not be taken back in any circumstances.",
    "No duplicate bill shall be issued.",
    "Check the vehicle properly before taking the delivery.We shall not be responsible for any sort of claims ones the vehicle is delivered.",
    "The customer is advise / informed to get his vehicle fully insured before taking the delivery, as his shall be held, fully responsible for any sort of damage caused or loss occurred any person, vehicle or anything due to any accident, theft, fire or riot etc.",
    "The person who signs as the customer himself / herself or as the authorized representative of the customer is totally responsible for any sort of liabilities such as Octroi, R.T.O.Charges, Municipal Tax, Insurance etc., or any sort of enquiry from any department.",
    "Showroom will remain closed on Sunday",
    "Price ruling at the time of delivery shall be applicable.Payment done through Cheque / DD / PO will be credited on realization of the same.",
    "All disputes shall be solved at SURAT jurisdiction only"
  ];
  return <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        <View style={styles.header} key='header'>
          <Text style={{ fontWeight: "bold", fontSize: '12px', marginBottom: '15px' }} key='header-1'>{company?.name?.toUpperCase()}</Text>
          <Text style={{ fontSize: '9px', marginBottom: '15px' }} key='header-2'>{company?.address?.toUpperCase()}, {company?.city?.name?.toUpperCase()},{company?.state?.name?.toUpperCase()}</Text>
          <Text style={{ fontSize: '9px', marginBottom: '25px' }} key='header-3'>PH.NO. 02612576000 CARE 9081033434</Text>
          <Text style={{ width: '100%', fontSize: '11px', fontWeight: 'bold', textAlign: 'center', marginBottom: '15px' }} key='header-4'>
            DELIVERY CHALLAN
          </Text>
          <Text style={{ width: '100%', fontSize: '9px', textAlign: 'center', marginBottom: '15px' }} key='header-5'>
            GSTIN: {company?.gst_no?.toUpperCase()} Dt :{moment(entry?.challandate).format('DD/MM/YYYY')}
          </Text>
        </View>

        {/* Invoice Info */}
        <View style={styles.table} key='body'>
          <View style={{ ...styles.tableRow, paddingTop: 5 }} key='body-view-1'>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>AC Code</Text>
            <Text style={{ width: '50%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.identity}</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }} />
            <Text style={{ width: '25%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }} />
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Name</Text>
            <Text style={{ width: '50%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.name}</Text>

            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }} />
            <Text style={{ width: '25%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }} />
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Address</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left', alignSelf: 'flex-start' }}>: </Text>
            <Text style={{ width: '89%', fontSize: '9px', textAlign: 'left' }}>{entry?.party?.address?.replaceAll('\n', '')}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>City</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.city?.name || 'SURAT'}</Text>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Dist</Text>
            <Text style={{ width: '15%', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.zone?.name || 'SURAT'}</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }} />
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }} />
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Pin Code</Text>
            <Text style={{ width: '15%', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.pincode || ''}</Text>
            <Text style={{ width: '20%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Place of Supply</Text>
            <Text style={{ width: '55%', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.state?.name || 'GUJARAT'}</Text>

          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Mobile No</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.mobile || ''}</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}> {entry?.party?.mobile || ''}</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>PAN No.</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}>: {entry?.party?.pan_no || ''}</Text>
          </View>


          <View style={{ ...styles.tableRow, marginTop: 0, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '7%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Vehicle</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '30%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }}>{vehicleInformation?.stockdetail?.product?.name || ''}</Text>

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Colour</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '15%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }}>{vehicleInformation?.stockdetail?.acolour?.name || ''}</Text>

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Document No.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '21%', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>{branch?.short_name || ''}/{padWithZeros(entry.identity, 6)}</Text>
          </View>
          <View style={{ ...styles.tableRow, marginTop: 0, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '7%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Model</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '30%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }}>{vehicleInformation?.stockdetail?.product?.avariant?.name || ''}</Text>

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>FSC No.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '15%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }} />

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Document Dt.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '21%', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>{moment(entry.challandate).format("DD/MM/YYYY") || ''}</Text>
          </View>
          <View style={{ ...styles.tableRow, marginTop: 0, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '7%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Hypoth</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '30%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }}>{entry?.party?.booking?.hypo}</Text>

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Battery No.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '15%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }} >{vehicleInformation?.stockdetail?.batteryNo || ''}</Text>

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Booking No.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '21%', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>{padWithZeros(entry?.party?.booking?.identity, 8)}</Text>
          </View>
          <View style={{ ...styles.tableRow, marginTop: 0, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }} >Engine No.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '27%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }} >{vehicleInformation?.stockdetail?.enginNo || ''}</Text>

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Key No.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '15%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 1 }} >{vehicleInformation?.stockdetail?.batteryNo || ''}</Text>

            <Text style={{ width: '12%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>Chassis No.</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>:</Text>
            <Text style={{ width: '21%', fontSize: '8px', textAlign: 'left', height: '15px', padding: 1 }}>{vehicleInformation?.stockdetail?.chassisNo || ''}</Text>
          </View>

          <View style={{ ...styles.tableRow, marginBottom: 0, height: '20px', borderTop: '1px solid black', borderBottom: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '100%', height: '20px', fontWeight: 'bold', fontSize: '10px', textAlign: 'right' }} />
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, marginTop: 3, textAlign: 'center' }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left' }} >
              TERMS & CONDITION :
            </Text>
          </View>
          {
            terms.map((x, index) => <View key={index + x} style={{ ...styles.tableRow, marginBottom: 0, textAlign: 'center', paddingTop: index === 0 ? 3 : 0, paddingBottom: index === terms.length - 1 ? 5 : 0 }}>
              <Text style={{ width: '3%', fontSize: '7px', textAlign: 'left' }} >
                ({index + 1})
              </Text>
              <Text style={{ width: '97%', fontSize: '7px', textAlign: 'left' }} >
                {x}
              </Text>
            </View>)
          }
          <View style={{ ...styles.tableRow, paddingTop: 2, marginBottom: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '10px', textAlign: 'right' }} >
              For AMIDEEP AUTOMOBILES
            </Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '25%', fontSize: '7.5px', textAlign: 'right', height: '30px', borderBottom: '1px solid black', marginBottom: 5 }} />
            <Text style={{ width: '15%', fontSize: '7.5px', textAlign: 'right', height: '30px' }} />
            <Text style={{ width: '20%', fontSize: '7.5px', textAlign: 'right', height: '30px', borderBottom: '1px solid black', marginBottom: 5 }} />
            <Text style={{ width: '15%', fontSize: '7.5px', textAlign: 'right', height: '30px' }} />
            <Text style={{ width: '25%', fontSize: '7.5px', textAlign: 'right', height: '30px', borderBottom: '1px solid black', marginBottom: 5 }} />
          </View>

          <View style={{ ...styles.tableRow, paddingTop: 0, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '33%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left', }} >
              Customer Signature
            </Text>
            <Text style={{ width: '33%', fontWeight: 'bold', fontSize: '10px', textAlign: 'center', }} >
              Prepared By
            </Text>
            <Text style={{ width: '34%', fontWeight: 'bold', fontSize: '10px', textAlign: 'right', }} >
              Authorized Signatory
            </Text>
          </View>
          <View style={{
            marginTop: 10,
            padding: 0,
            flexGrow: 1,
            borderTop: '2px dashed black',
            marginBottom: 5
          }} />
          <View style={{ ...styles.tableRow, paddingTop: 0, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '33%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left', }} >
              GATEPASS NO
            </Text>
            <Text style={{ width: '33%', fontWeight: 'bold', fontSize: '10px', textAlign: 'center', }} >
              GATE PASS
            </Text>
            <Text style={{ width: '34%', fontSize: '10px', textAlign: 'right', }} >
              DATE : {moment(entry?.challandate).format('DD/MM/YYYY')}
            </Text>
          </View>
          <View style={{ ...styles.tableRow, paddingTop: 0, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '13%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left', }} >
              PRODUCT :
            </Text>
            <Text style={{ width: '33%', fontSize: '10px', textAlign: 'center', }} >
              {vehicleInformation?.stockdetail?.product?.name || ''}
            </Text>
            <Text style={{ width: '20%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left', }} />
            <Text style={{ width: '34%', fontSize: '10px', textAlign: 'right', }} >
              OPTION : Self Start
            </Text>
          </View>
          <View style={{ ...styles.tableRow, marginTop: 5, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '20%', fontWeight: 'bold', fontSize: '9px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}>Chassis No.</Text>
            <Text style={{ width: '16%', fontWeight: 'bold', fontSize: '9px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}> Engine No.</Text>
            <Text style={{ width: '30%', fontWeight: 'bold', fontSize: '9px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}>Colour</Text>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '9px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}>Key No.</Text>
            <Text style={{ width: '16%', fontWeight: 'bold', fontSize: '9px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}>Battery No.</Text>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '9px', textAlign: 'center', height: '15px', padding: 2 }}>FSC No.</Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', borderBottom: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '20%', fontSize: '8px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}>{vehicleInformation?.stockdetail?.chassisNo || ''}</Text>
            <Text style={{ width: '16%', fontSize: '8px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}> {vehicleInformation?.stockdetail?.enginNo || ''}</Text>
            <Text style={{ width: '30%', fontSize: '8px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}>{vehicleInformation?.stockdetail?.acolour?.name || ''} </Text>
            <Text style={{ width: '10%', fontSize: '8px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '16%', fontSize: '8px', textAlign: 'center', borderRight: '1px solid black', height: '15px', padding: 2 }}>{vehicleInformation?.stockdetail?.batteryNo || ''}</Text>
            <Text style={{ width: '10%', fontSize: '8px', textAlign: 'center', height: '15px', padding: 2 }} />
          </View>

          <View style={{ ...styles.tableRow, paddingTop: 0, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '100%', fontSize: '10px', textAlign: 'left', }} >
              PARTY NAME : {entry?.party?.name}
            </Text>
          </View>
          <View style={{ ...styles.tableRow, paddingTop: 0, marginTop: 10, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '10px', textAlign: 'center', }} >
              SATISFACTION NOTE
            </Text>
          </View>
          <View style={{ ...styles.tableRow, paddingTop: 0, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '100%', fontSize: '10px', textAlign: 'left', }} >
              I undersigned Customer / Authorized Representative accepts that i have received the above mentioned vehicle with all its tools / accessories / respective papers etc. in good and proper condition.
            </Text>
          </View>
        </View>
        <View style={[styles.footer]} fixed>
          <View style={{ ...styles.tableRow, marginTop: 50, textAlign: 'center' }}>
            <Text style={{ width: '50%', fontSize: '10px', textAlign: 'right', height: '10px' }} />
            <Text style={{ width: '50%', fontSize: '10px', textAlign: 'right', height: '10px', borderBottom: '1px solid black', marginBottom: 1 }} />
          </View>
          <View style={{ ...styles.tableRow, fontSize: '10px', textAlign: 'left', }}>
            <Text style={{ width: '50%', fontSize: '10px', textAlign: 'left' }}>
              Subject to SURAT Jurisdiction
            </Text>
            <Text style={{ width: '50%', fontWeight: 'bold', textAlign: 'right' }}>Customer / Representative Sign E. & O.E.</Text>
          </View>
        </View>
      </Page>
    </Document>
  </PDFViewer>;
}

ChallanPDF.propTypes = {
  invoice: PropTypes.object,
};