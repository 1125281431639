/* eslint-disable no-restricted-globals */
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      enqueueSnackbar('Server not respond!', {
        variant: 'error',
      });
    } else if ([400, 404, 415, 409].includes(error.response.status)) {
      enqueueSnackbar(error.response.data.message || 'Something Wrong', {
        variant: 'error',
      });
    } else if (error.response.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.href = '/';
      location.reload();
      enqueueSnackbar(error.response.data.message || 'Something Wrong', {
        variant: 'error',
      });
    } else if (error.response.status === 403) {
      enqueueSnackbar(error.message || 'Something Wrong', {
        variant: 'error',
      });
    } else if (error.code === 'ERR_BAD_REQUEST') {
      enqueueSnackbar('Something Wrong!', {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
    return Promise.reject((error.response && error.response.data) || error.message);
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args, original = false) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });
  if (original) return res;
  return res.data;
};
export const create = async (args) => {
  const { url, payload, config } = args;

  const res = await axiosInstance.post(url, payload, { ...config });
  return res.data;
};
export const update = async (args) => {
  const { url, payload, config } = args;

  const res = await axiosInstance.put(url, payload, { ...config });
  return res.data;
};
export const destroy = async (args) => {
  const { url, config } = args;

  const res = await axiosInstance.delete(url, { ...config });
  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: 'users/me',
    put: 'users/',
    login: 'users/login',
    register: 'users/register',
    advance: 'users/advance/',
  },
  company: 'company',
  modules: 'modules',
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
