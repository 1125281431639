import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import {
  amideepautomobilebranch,
  CASH_DEBIT,
  INVOI_TYPE,
  KEY,
  PURCHASE_TYPE,
  SALES_TYPE,
  Tax_BilSupply,
} from 'src/_mock/constant';
import {
  Autocomplete,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  TableFooter,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { Box, Stack, alpha } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import {
  calculateNetAmount,
  convertToRound,
  fMoney,
  nearestDateList,
  onScollerAutoComplate,
  sanitizeComplexObject,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { isNaN } from 'lodash';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/automobile-accounts/account/quick-edit-form';
import PUserQuickEditForm from 'src/sections/automobile-products/product-list/quick-edit-form';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CompanyYear from 'src/layouts/_common/company-year';
import { AuthContext } from 'src/auth/context/jwt';
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@mui/styles';
import GSTCommodityQuickEditForm from 'src/sections/automobile-gst/commodity/quick-edit-form';
import QuickEditForm from 'src/sections/credit-card/quick-edit-form';
import { useDebounce } from 'src/hooks/use-debounce';
import { handleScroll } from 'src/layouts/_common/scroller';
import moment from 'moment/moment';

let tranType = null;
const va = {
  1: {
    cb: 1,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'Payment',
    ac_groupId: '22',
  },
  2: {
    cb: 2,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'receipt',
    ac_groupId: '22',
  },
  3: {
    cb: 1,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'Payment',
    ac_groupId: '26',
  },
  4: {
    cb: 2,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'receipt',
    ac_groupId: '26',
  },
  5: {
    cb: 1,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'Payment',
    ac_groupId: '22',
  },
  6: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '22',
  },
  8: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '10',
  },
  9: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '11',
  },
};
const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: theme.palette.primary.main,
  },
}));

export default function UserQuickEditForm({
  quickChallanId,
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
  invoiceSettings,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const fileRef = useRef(null);

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const classes = useStyles();

  const bgColorAutocomplete = useBgColorAutocomplete(); // Use the hook here

  const theme = useTheme();

  const tableBodyRef = useRef(null);

  const quickEditAccount = useBoolean();
  const quickEditProduct = useBoolean();
  const quickEditGSTCommdity = useBoolean();
  const confirm = useBoolean();
  const quickEdit = useBoolean();

  let gTotal = 0;

  const { user } = useContext(AuthContext);
  const [entry, setEntry] = useState(null);
  const [partySearch, setPartySearch] = useState(null);
  const [purchaseSearch, setPurchaseSearch] = useState(null);
  const [insuranceData, setInsuranceData] = useState([]);
  const [importLoading, setImportLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [colourData, setColourData] = useState([]);
  const [goDownData, setgoDownData] = useState([]);
  const [productVariantData, setProductVariantData] = useState([]);
  const [creditAccountList, setCreditAccountList] = useState([]);
  const [credit, setCredit] = useState({});
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [pAccountList, setPAccountList] = useState([]);
  const [trasaction, setTrasaction] = useState([]);
  const [isSuccessful, setisSuccessful] = useState(false);
  const [accountEditList, setaccountEditList] = useState({});
  const [SVBooking, setSVBooking] = useState({});
  const [PaccountEditList, setPAccountEditList] = useState({});
  const [productEditValues, setProductEditValues] = useState({});
  const [GSTCommodity, setGSTCommodity] = useState({});
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [yearData, setYearData] = useState([]);

  const [challanData, setChallanData] = useState({});
  const [challanLoading, setChallanLoading] = useState(false);
  const [importDisabled, setImportDisabled] = useState(false);

  const [total, setTotal] = useState({
    CGST: 0,
    SGST: 0,
    IGST: 0,
    ItemAmount: 0,
    TotalAmount: 0,
    BillAmount: 0,
  });

  const PurchaseOrderSchema = Yup.object().shape({
    c_d: Yup.number().nullable().min(0).required('Cash/Dabit is required'),
    in_type: Yup.number().integer().required(' is required'),
    bill_no: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    inv_details: Yup.array().of(
      Yup.object().shape({
        productId: Yup.number().nullable().required('Product is required'),
        qty: Yup.number()
          .nullable()
          .min(0)
          .required('Qty is required')
          .transform((value) => (isNaN(value) ? null : value)),
        rate: Yup.number()
          .nullable()
          .min(0)
          .required('Rate is required')
          .transform((value) => (isNaN(value) ? null : value)),
        dprice: Yup.number()
          .nullable()
          .min(0)
          .notRequired()
          .transform((value) => (isNaN(value) ? null : value)),
        drate: Yup.number()
          .nullable()
          .min(0)
          .notRequired()
          .transform((value) => (isNaN(value) ? null : value)),
      })
    ),
    entry_details: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().integer().nullable(),
        cb: Yup.number().integer().required(),
        accountId: Yup.number().integer().required(),
        bentryId: Yup.number().integer().nullable(),
        entry_detailId: Yup.number().integer().nullable(),
        bpoptionId: Yup.number()
          .integer()
          .nullable()
          .transform((value, originalValue) => (originalValue === null ? null : originalValue)),
        ac_no: Yup.string().nullable(),
        // amount: Yup.number().required(),
      })
    ),
  });

  const cyearId = user?.co_year?.id;
  const GETBYID = async (id) => {
    try {
      let URL = `automobile/entries/${id}`;
      URL += `?status=true&`;
      const { data } = await fetcher(URL);
      if (data)
        setEntry(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GETBYID(row?.id);
  }, [row])
  const values = useMemo(
    () => {
      const isIgst = entry?.entry_details?.map(x => x.account.name).find(y => y === 'IGST');
      let in_type = entry?.in_type || null;

      if (isIgst) {
        in_type = 2;
      } else {
        in_type = 1;
      }

      return ({
        yearId: entry?.yearId || cyearId,
        challanId: entry?.challanId || null,
        identity: entry?.identity || null,
        challanNo: entry?.challan?.identity ? `${amideepautomobilebranch[entry.branchId]} ${entry?.challan?.identity}` : null,
        challandate: entry?.challan?.challandate || new Date(),
        c_d: entry?.c_d || 2,
        in_type: entry?.in_type || 3,
        otheraccountId: entry?.otheraccountId || null,
        invo_type: entry?.invo_type || 1,
        pcategoryId: entry?.pcategoryId || null,
        pcategory: entry?.pcategory ? { ...entry?.pcategory, a: 'name' } : null,
        t_type: entry?.t_type || null,
        bill_no: entry?.bill_no || null,
        LRefNo: entry?.LRefNo || null,
        v_no: entry?.v_no || null,
        gst_no: entry?.entry_details?.find((x) => x.order === 1)?.account?.gst_no || null,
        id: entry?.id || null,
        tax_bi_Id: entry?.tax_bi_Id || null,
        docNo: entry?.docNo || null,
        docDate: entry?.docDate || new Date(),
        invNo: entry?.invNo || null,
        invDate: entry?.invDate || new Date(),

        covernoteno: entry?.covernoteno || '',
        covernotedate: entry?.covernotedate || null,
        covernoteexpiredate: entry?.covernoteexpiredate || null,
        insuranceagentId: entry?.insuranceagentId || null,

        truckNo: entry?.truckNo || null,
        transportName: entry?.transportName || null,
        receivedDate: entry?.receivedDate || '',
        godownId: entry?.godownId || null,
        inTras: !entry?.receivedDate,
        dt: entry?.dt || new Date(),
        b_date: entry?.b_date || new Date(),
        narr: entry?.narr || '',
        inv_details:
          entry?.inv_details?.map((data) => ({
            id: data?.id || null,
            productId: data?.productId || null,
            productName: null,
            product: null,
            qty: data?.qty || null,
            rate: data?.rate || null,
            mrp: data?.mrp || null,
            dprice: data?.dprice || null,
            drate: data?.drate || null,
            CGST: data?.CGST || null,
            SGST: data?.SGST || null,
            IGST: data?.IGST || null,
          })) ||
          [...Array(5)].map(() => ({
            id: null,
            productId: null,
            productName: null,
            product: null,
            qty: null,
            rate: null,
            mrp: null,
            dprice: null,
            drate: null,
            CGST: null,
            SGST: null,
            IGST: null,
          })),
        stockdetails:
          entry?.stockdetails?.map((xdata) => ({
            id: xdata?.id || null,
            pname: xdata?.avariant?.name || null,
            productId: xdata?.productId || null,
            rate: xdata?.rate || null,
            mrp: xdata?.mrp || null,
            instock: xdata?.instock || null,
            chassisNo: xdata?.chassisNo || '',
            enginNo: xdata?.enginNo || '',
            acolourId: xdata?.acolourId || '',
            keyNo: xdata?.keyNo || '',
            batteryNo: xdata?.batteryNo || '',
          })) ||
          [...Array(1)].map(() => ({
            id: null,
            pname: null,
            productId: null,
            rate: null,
            instock: null,
            chassisNo: '',
            enginNo: '',
            acolourId: '',
            keyNo: '',
            batteryNo: '',
          })),
        entry_details: (entry?.entry_details &&
          Array.isArray(entry?.entry_details) &&
          entry?.entry_details
            ?.sort((a, b) => a.order - b.order)
            ?.map((x) => {
              x.in_type = in_type;
              return {
                ...(x ? { id: x.id } : {}),
                ...(x.in_type ? { in_type: x?.in_type } : {}),
                cb: x?.cb || 1,
                cd: x?.cd || 1,
                accountId: x?.accountId || null,
                account: x?.account || null,
                entryId: x?.entryId || null,
                bentryId: x?.bentryId || null,
                entry_detailId: x?.entry_detailId || null,
                bpoptionId: x?.bpoptionId || null,
                ac_no: x?.ac_no || null,
                amount: entry?.entry_details.find(y => y.accountId === x.accountId)?.amount || 0,
                order: x?.order || null,
                accountlabel: `${x?.account?.s_n}` || null,
                accountMeta: {
                  page: 1,
                  name: '',
                  totalPage: 1,
                  editRow: null,
                },
              };
            })) || [
            {
              cb: 1,
              cd: 1,
              accountId: null,
              entryId: null,
              bentryId: null,
              entry_detailId: null,
              bpoptionId: null,
              ac_no: null,
              amount: null,
              order: 1,
              account: null,
              accountMeta: {
                page: 1,
                name: '',
                totalPage: 1,
                editRow: null,
              },
            },
            {
              cb: 1,
              cd: 1,
              accountId: null,
              entryId: null,
              bentryId: null,
              entry_detailId: null,
              bpoptionId: null,
              ac_no: null,
              amount: null,
              order: 2,
              account: null,
              accountMeta: {
                page: 1,
                name: '',
                totalPage: 1,
                editRow: null,
              },
            },
          ],
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entry, trasaction]
  );


  const colourDataList = useCallback(async () => {
    try {
      let URL = 'automobile/product/colours/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setColourData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const HandleProductCategoryList = async () => {
    try {
      let URL = 'pcategories/';
      URL += `?page=${1}&limit=${100}&status=true&`;
      const { data } = await fetcher(URL);
      setProductCategoryList(data?.rows.map((x) => ({ ...x, a: 'name' })).filter(y => [0, 1].includes(y.department)));
    } catch (error) {
      console.error(error);
    }
  };


  const methods = useForm({
    resolver: yupResolver(PurchaseOrderSchema),
    values,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    getFieldState,
    formState: { isSubmitting, errors },
  } = methods;

  const stockdetails = useFieldArray({
    control,
    name: 'stockdetails',
  });
  const handlestockdetailsAdd = useCallback(() => {
    stockdetails?.append({
      id: null,
      pname: null,
      productId: null,
      rate: null,
      instock: null,
      chassisNo: '',
      enginNo: '',
      acolourId: '',
      keyNo: '',
      batteryNo: '',
    });
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [stockdetails]);

  const handleRemoveStockDetail = useCallback(
    (index) => {
      stockdetails.remove(index);
    },
    [stockdetails]
  );
  const allValues = watch();
  const debouncedPartyAccount = useDebounce(allValues.entry_details[0].accountMeta?.name);
  const debouncedOppAccount = useDebounce(allValues.entry_details[1].accountMeta?.name);

  const inputRefs = useRef(allValues.inv_details.map(() => React.createRef()));

  const GST_NUMBER = user?.companies?.find((x) => x.id === user?.companyId)?.gst_no;
  const GST_Company = GST_NUMBER?.substring(0, 2);
  const GST_PARTY = allValues?.gst_no?.substring(0, 2);

  const CStateId = user?.companies?.find((x) => x.id === user?.companyId)?.stateId;
  const stateId = allValues.entry_details.find(y => y.order === 1)?.account?.stateId;

  useEffect(() => {
    if (!GST_Company || (!GST_PARTY && allValues.tax_bi_Id !== 1)) {
      setValue('in_type', 3);
    } else if ((GST_NUMBER && GST_PARTY && GST_Company === GST_PARTY) || stateId === CStateId) {
      setValue('in_type', 1);
    } else if ((GST_NUMBER && GST_PARTY && GST_Company !== GST_PARTY) || (typeof stateId === "number" && typeof CStateId === "number" && stateId !== CStateId)) {
      setValue('in_type', 2);
    }
  }, [CStateId, GST_Company, GST_NUMBER, GST_PARTY, allValues.tax_bi_Id, setValue, stateId]);

  const getProductById = useCallback(
    async (code, index) => {
      const URL = `automobile/products/?id=${code}&page=1&limit=10000&status=true&pcategoryId=${allValues?.pcategoryId}`;
      try {
        if (code === '') {
          setValue(`stockdetails[${index}]`, {
            ...allValues.stockdetails[index],
            pname: '',
            productId: null,
          });
          return;
        }

        const { data } = await fetcher(URL);
        if (data?.rows?.length) {
          const product = data.rows[0];
          if (product)
            setValue(`stockdetails[${index}]`, {
              ...allValues.stockdetails[index],
              pname: product.name,
              productId: product.id,
            });
        } else {
          setValue(`stockdetails[${index}]`, {
            ...allValues.stockdetails[index],
            pname: '',
            productId: null,
          });
          enqueueSnackbar('Product not Available!', { variant: 'info' });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch product details!', { variant: 'error' });
      }
    },
    [allValues?.pcategoryId, allValues.stockdetails, setValue, enqueueSnackbar]
  );

  useEffect(() => {
    if (allValues.receivedDate === null) {
      setValue('inTras', true);
    } else {
      setValue('inTras', false);
    }
  }, [allValues.receivedDate, setValue]);

  const onSubmit = handleSubmit(async (payload) => {
    try {
      payload = sanitizeComplexObject(allValues);
      payload.inv_details = allValues.inv_details
        .filter((x, index) => x.rate && x.qty)
        .map((y) => {
          const GSTRATE = nearestDateList(
            productList.find((x) => x.id === y.productId)?.gst_commodity?.gst_com_details
          );
          return {
            ...y,
            CGST: GSTRATE?.gst_rate?.CGST,
            SGST: GSTRATE?.gst_rate?.SGST,
            IGST: GSTRATE?.gst_rate?.IGST,
          };
        });

      payload.amount = convertToRound(total.BillAmount);
      let URL = 'automobile/entries/';
      const pursaleaccount = allValues.entry_details.find((x) => x.order === 2)?.accountId;
      // const cd = allValues.c_d;
      const resetValues = { ...values };
      resetValues.entry_details[1].accountId = pursaleaccount;
      // resetValues.c_d = cd;

      if (allValues?.tax_bi_Id === 3) {
        const unique = new Set();
        trasaction.forEach((x) => {
          x.tfields.forEach((y) => {
            unique.add(y.accountId);
          });
        });
        payload.entry_details = allValues.entry_details.filter((x) => !unique.has(x.accountId));
      }

      if (entry?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          if (show) {
            enqueueSnackbar('Update success!');
          }
          getAll();
          reset(resetValues);
          onClose();
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          if (show) {
            enqueueSnackbar('Create success!');
          }
          getAll();
          reset(resetValues);
          document.getElementById('invoice_type').focus();
        }
      }
      setImportDisabled(false);
    } catch (error) {
      reset(payload);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });
  const goDownList = useCallback(async () => {
    try {
      let URL = 'automobile/godowns';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setgoDownData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const productVariantList = useCallback(async () => {
    try {
      let URL = 'automobile/product/variants/';
      URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=46&`;
      const { data } = await fetcher(URL);
      if (data) {
        setProductVariantData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     allValues?.pcategoryId &&
  //     productCategoryList.find((x) => x.id === allValues?.pcategoryId)?.name?.toLowerCase() ===
  //     'vehicle'
  //   ) {

  //     const groupByCode = allValues.stockdetails.reduce((acc, item) => {
  //       if (item.productId) {
  //         if (!acc[item.productId]) {
  //           acc[item.productId] = {
  //             id: null,
  //             productId: item.productId,
  //             pname: item.pname,
  //             qty: 0,
  //             totalrate: 0,
  //             rate: item.rate,
  //             amount: item.amount,
  //             discount: item.discount,
  //             gst: item.gst,
  //             netAmount: item.netAmount,
  //           };
  //         }
  //         acc[item.productId].qty += 1; // Increment the quantity for each occurrence
  //         acc[item.productId].totalrate += item.rate; // Increment the quantity for each occurrence
  //       }
  //       return acc;
  //     }, {});
  //     // Convert the grouped items into an array and update the stocks state
  //     const newStocks = Object.values(groupByCode).map((stock) => {
  //       const alreadyExists = allValues.inv_details.find((x) => x.productId === stock.productId);
  //       return {
  //         ...alreadyExists,
  //         ...stock,

  //         rate: stock.totalrate / stock.qty,
  //         amount: (stock.totalrate / stock.qty) * stock.qty, // Calculate the total amount based on rate and quantity
  //         gst: allValues.inv_details.find((x) => x.productId === stock.productId)?.gst || 0,
  //         netAmount:
  //           (stock.totalrate / stock.qty) * stock.qty -
  //           (allValues.inv_details.find((x) => x.productId === stock.productId)?.discount || 0) +
  //           (stock.gst || 0),
  //       };
  //     });

  //     if (JSON.stringify(newStocks) !== JSON.stringify(allValues.inv_details)) {
  //       console.log('useeffect 3');
  //       setValue('inv_details', newStocks);
  //     }
  //   }
  // }, [allValues.inv_details, allValues?.pcategoryId, allValues.stockdetails, productCategoryList, setValue]);

  useEffect(() => {
    goDownList();
    productVariantList();
    colourDataList();
    reset();
  }, [colourDataList, goDownList, open, productVariantList, reset]);

  const inv_details = useFieldArray({
    control,
    name: 'inv_details',
  });
  const entry_details = useFieldArray({
    control,
    name: 'entry_details',
  });

  const TotalQty = allValues.inv_details?.reduce((a, b) => a + (parseFloat(b.qty) || 0), 0);
  const TotalDPrice = allValues.inv_details?.reduce(
    (a, b) =>
      a +
      parseFloat(
        parseFloat(b?.dprice) ||
        (b?.drate > 0 &&
          b?.rate > 0 &&
          ((parseFloat(b?.rate) * parseFloat(b?.drate)) / 100)?.toFixed(2)) ||
        0
      ),
    0
  );

  useEffect(() => {
    let itemAmount = 0;
    let BillAmount = 0;
    let TDPtotalAmount = 0;
    let TDRtotalAmount = 0;
    let TfinalTotal = 0;
    let TtotalAmount = 0;
    let TCGST = 0;
    let TSGST = 0;
    let TIGST = 0;
    let TotalAmount = 0;

    allValues.inv_details?.forEach((field, index) => {
      const gstRate = nearestDateList(
        productList.find((x) => x.id === allValues.inv_details[index]?.productId)?.gst_commodity
          ?.gst_com_details
      );

      let DPtotalAmount = 0;
      let DRtotalAmount = 0;
      let GrossAmount = 0;
      let finalTotal = 0;
      let totalAmount = 0;
      let CGST = 0;
      let SGST = 0;
      let IGST = 0;
      const price = allValues.t_type === 9 ? allValues.inv_details[index]?.mrp : allValues.inv_details[index]?.rate;
      if (price && allValues.inv_details[index]?.qty) {
        totalAmount = price * allValues.inv_details[index].qty;
      }
      if (allValues.inv_details[index]?.dprice) {
        DPtotalAmount =
          (price - allValues.inv_details[index].dprice) *
          allValues.inv_details[index].qty;
      }
      if (allValues.inv_details[index]?.drate) {
        const rq = price * allValues.inv_details[index].qty;
        DRtotalAmount = rq - (rq / 100) * allValues.inv_details[index].drate;
      }

      if (DPtotalAmount || allValues.inv_details[index]?.dprice) {
        finalTotal = DPtotalAmount;
      } else if (DRtotalAmount || allValues.inv_details[index]?.drate) {
        finalTotal = DRtotalAmount;
      } else {
        finalTotal = totalAmount;
      }

      GrossAmount = finalTotal;
      itemAmount += GrossAmount;
      TotalAmount += GrossAmount;
      const rate = allValues.inv_details[index].rate;
      if (allValues?.in_type === 1 && [1, 4].includes(allValues.tax_bi_Id)) {
        SGST = (rate / 100 || 0) * (gstRate?.gst_rate?.SGST || 0);
        CGST = (rate / 100 || 0) * (gstRate?.gst_rate?.CGST || 0);
        if (allValues.tax_bi_Id === 1) {
          if (allValues.t_type === 8) {

            finalTotal += CGST + SGST;
            TotalAmount += CGST + SGST;
          }
        }
      } else if (allValues?.in_type === 2 && [1, 4].includes(allValues.tax_bi_Id)) {
        IGST = (rate / 100 || 0) * (gstRate?.gst_rate?.IGST || 0);
        if (allValues.tax_bi_Id === 1) {
          if (allValues.t_type === 8) {

            finalTotal += IGST;
            TotalAmount += IGST;
          }
        }
      }

      TDPtotalAmount += DPtotalAmount;
      TDRtotalAmount += DRtotalAmount;
      TfinalTotal += finalTotal;
      TtotalAmount += totalAmount;
      TCGST += CGST;
      TSGST += SGST;
      TIGST += IGST;

      BillAmount += finalTotal;
    });
    const restTotal = entry_details.fields.reduce((pre, next) => {
      if (
        [
          'TCS',
          'INSURANCE',
          'RTO',
          'HPA',
          'ROAD TAX',
          'R.T.O.',
          'INSURANCE',
          'RTO AGF',
          'EXT.WARR.',
          'H.P.A',
          'EXTENDED_WARRANTY',
          'COATING',
          'ROADTAX',
          'RTO AGENT FEE',
          'OTHER',
          'DISCOUNT',
          'DISCOUNTTRADING'
        ].includes(next?.accountlabel?.trim())
      ) {
        if (next.accountlabel === 'DISCOUNTTRADING' || next.accountlabel === 'DISCOUNT') pre -= parseFloat(next.amount) || 0;
        else pre += parseFloat(next.amount) || 0;
      }
      return pre;
    }, 0);
    const newtotal = {
      CGST: TCGST ? parseFloat(TCGST.toFixed(2)) : 0,
      SGST: TSGST ? parseFloat(TSGST.toFixed(2)) : 0,
      IGST: TIGST ? parseFloat(TIGST.toFixed(2)) : 0,
      ItemAmount: itemAmount,
      TotalAmount,
      BillAmount: BillAmount + restTotal,
    };
    if (JSON.stringify(total) !== JSON.stringify(newtotal)) {
      setTotal(newtotal);
    }
    if (
      allValues?.pcategoryId &&
      productCategoryList.find((x) => x.id === allValues?.pcategoryId)?.name?.toLowerCase() ===
      'vehicle'
    ) {
      const groupByCode = allValues.stockdetails.reduce((acc, item) => {
        if (item.productId) {
          if (!acc[item.productId]) {
            acc[item.productId] = {
              id: null,
              productId: item.productId,
              pname: item.pname,
              qty: 0,
              totalrate: 0,
              rate: item.rate,
              amount: item.amount,
              discount: item.discount,
              gst: item.gst,
              netAmount: item.netAmount,
            };
          }
          acc[item.productId].qty += 1; // Increment the quantity for each occurrence
          acc[item.productId].totalrate += item.rate; // Increment the quantity for each occurrence
        }
        return acc;
      }, {});
      // Convert the grouped items into an array and update the stocks state
      const newStocks = Object.values(groupByCode).map((stock) => {
        const alreadyExists = allValues.inv_details.find((x) => x.productId === stock.productId);

        return {
          ...alreadyExists,
          ...stock,

          rate: stock.totalrate / stock.qty,
          amount: (stock.totalrate / stock.qty) * stock.qty, // Calculate the total amount based on rate and quantity
          gst: allValues.inv_details.find((x) => x.productId === stock.productId)?.gst || 0,
          netAmount:
            (stock.totalrate / stock.qty) * stock.qty -
            (allValues.inv_details.find((x) => x.productId === stock.productId)?.discount || 0) +
            (stock.gst || 0),
        };
      });

      if (JSON.stringify(newStocks) !== JSON.stringify(allValues.inv_details)) {
        setValue('inv_details', newStocks);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues, productList, total, setTotal, allValues.tax_bi_Id, entry_details]);

  useEffect(() => {
    if (!row) {
      const list = allValues.inv_details?.filter((x) => x?.productId);

      const mainGSTTotal = list.reduce((pre, next) => {
        const gstRate = nearestDateList(
          productList.find((x) => x.id === next.productId)?.gst_commodity?.gst_com_details
        );
        if (Object.keys(gstRate).length === 0 || (!gstRate && !gstRate?.gst_rate?.IGST))
          return pre + 0;
        let amount =
          (((next.rate - (next.dprice || 0)) * next.qty) / 100) *
          (next.IGST || gstRate.gst_rate.IGST || 0);
        amount = parseFloat(amount);

        return pre + amount;
      }, 0);
      const extendwarranty = 0;
      const ROADTAXTotal = 0;
      const HPCHARGES = 0;
      const RTOTotal = 0;
      const insuranceTOTAL = 0;
      const otherTOTAL = 0;
      // if (productCategoryList.find((x) => x.id === allValues?.pcategoryId)?.name?.toLowerCase() ===
      //   'vehicle') {
      //   extendwarranty = list.reduce(
      //     (pre, next) => pre + next.product.avariant.price.extwar || 0,
      //     0
      //   );
      //   ROADTAXTotal = list.reduce(
      //     (pre, next) => pre + next.product.avariant.price.roadtax || 0,
      //     0
      //   );
      //   HPCHARGES = list.reduce(
      //     (pre, next) => pre + next.product.avariant.price.hpcharges || 0,
      //     0
      //   );
      //   RTOTotal = list.reduce((pre, next) => pre + next.product.avariant.price.rto || 0, 0);
      //   insuranceTOTAL = list.reduce(
      //     (pre, next) => pre + next.product.avariant.price.insurance || 0,
      //     0
      //   );
      //   otherTOTAL = list.reduce((pre, next) => pre + next.product.avariant.price.others || 0, 0);
      // }

      const calculate = (name) => {
        switch (name) {
          case 'CGST':
            return mainGSTTotal / 2;
          case 'SGST':
            return mainGSTTotal / 2;
          case 'IGST':
            return mainGSTTotal;
          case 'INSURANCE':
            return insuranceTOTAL;
          case 'RTO':
            return RTOTotal;
          case 'HPA':
            return HPCHARGES;
          case 'RTO AGENT FEE':
            return otherTOTAL;
          case 'ROADTAX':
            return ROADTAXTotal;
          case 'EXTENDED_WARRANTY':
            return extendwarranty;
          default:
            return 0;
        }
      };
      if (row === null || !('id' in row))
        trasaction
          .find((x) => x.t_type === 9)
          ?.tfields.filter(
            (x) =>
              (allValues.in_type === 1 && x.name !== 'IGST') ||
              (allValues.in_type === 2 && !['SGST', 'CGST'].includes(x.name))
          )
          .forEach((x, index) => {
            const acId = !row
              ? trasaction.find((x) => x.t_type === 9)?.tfields?.find((y) => y?.name === x.name)
                ?.accountId
              : entry?.entry_details?.find((z) => z?.order === index + 3)?.accountId;
            const allReadyExistAccount = allValues.entry_details?.findIndex(
              (x) => x.accountId === acId
            );

            if (allReadyExistAccount >= 0) {
              if (x.name.trim().toLowerCase().includes('gst')) {
                entry_details.update(allReadyExistAccount, {
                  ...allValues.entry_details[allReadyExistAccount],
                  amount: calculate(x.name.trim()),
                  cb: allValues.t_type === 8 ? 2 : 1,
                  accountlabel: x.name,
                });
              } else {
                const index = allValues.in_type === 1 ? 4 : 3;
                if (allValues.entry_details[allReadyExistAccount].order > index)
                  entry_details.update(allReadyExistAccount, {
                    ...allValues.entry_details[allReadyExistAccount],
                    amount: calculate(x.name.trim()),
                    cb: allValues.t_type === 8 ? 2 : 1,
                    accountlabel: x.name,
                  });
              }
            } else if (
              productCategoryList
                .find((x) => x.id === allValues?.pcategoryId)
                ?.name?.toLowerCase() !== 'vehicle' ||
              allValues.t_type === 8
            ) {
              if (
                ![
                  'TCS',
                  'INSURANCE',
                  'RTO',
                  'HPA',
                  'ROAD TAX',
                  'R.T.O.',
                  'INSURANCE',
                  'RTO AGF',
                  'EXT.WARR.',
                  'H.P.A',
                  'EXTENDED_WARRANTY',
                  'COATING',
                  'ROADTAX',
                  'RTO AGENT FEE',
                  'OTHER',
                  'DISCOUNT',
                  'DISCOUNTTRADING'
                ].includes(x.name.trim())
              ) {
                entry_details.append({
                  cb: x.name.trim() === 'DISCOUNT' && allValues.t_type === 9 ? 2 : 1,
                  accountId: acId,
                  entryId: null,
                  bentryId: null,
                  entry_detailId: null,
                  bpoptionId: null,
                  ac_no: null,
                  amount: calculate(x.name.trim()),
                  order: index + 3,
                  readOnly: false,
                  in_type: allValues.in_type,
                  accountlabel: x.name,
                });
              }
            } else {
              entry_details.append({
                cb: x.name.trim() === 'DISCOUNT' && allValues.t_type === 9 ? 2 : 1,
                accountId: acId,
                entryId: null,
                bentryId: null,
                entry_detailId: null,
                bpoptionId: null,
                ac_no: null,
                amount: calculate(x.name.trim()),
                order: index + 3,
                readOnly: false,
                in_type: allValues.in_type,
                accountlabel: x.name,
              });
            }
          });

      const findorder1index = allValues.entry_details.findIndex((x) => x.order === 1);
      const findorder2index = allValues.entry_details.findIndex((x) => x.order === 2);
      const ct = Math.round(
        allValues.entry_details
          .filter((x) => x.cb === 1)
          ?.reduce((pre, next) => pre + next.amount || 0, 0)
      )?.toFixed(2);
      const dt = Math.round(
        allValues.entry_details
          .filter((x) => x.cb === 2)
          ?.reduce((pre, next) => pre + next.amount || 0, 0)
      )?.toFixed(2);
      // if (findorder1index >= 0 && findorder2index >= 0) {
      //   entry_details.update(findorder1index, {
      //     ...allValues.entry_details[findorder1index],
      //     amount: allValues.tax_bi_Id === 1 ? total.ItemAmount : total.BillAmount,
      //     cb: allValues?.t_type === 8 ? 2 : 1,
      //   });
      //   entry_details.update(findorder2index, {
      //     ...allValues.entry_details[findorder2index],
      //     amount: allValues.tax_bi_Id === 1 ? total.ItemAmount : total.BillAmount,
      //     cb: allValues?.t_type === 8 ? 2 : 1,
      //   });
      // }
      // if (findorder1index >= 0 && findorder2index >= 0) {
      //   entry_details.update(findorder1index, {
      //     ...allValues.entry_details[findorder1index],
      //     amount: parseFloat(ct),
      //     cb: allValues?.t_type === 8 ? 2 : 1,
      //   });
      //   entry_details.update(findorder2index, {
      //     ...allValues.entry_details[findorder2index],
      //     amount: parseFloat(ct),
      //     cb: allValues?.t_type === 8 ? 2 : 1,
      //   });
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.in_type, allValues.inv_details, row, trasaction]);

  useEffect(() => {
    trasaction
      .find((x) => x.t_type === 9)
      ?.tfields?.sort((a, b) => a.seq - b.seq)
      ?.filter(
        (x) =>
          (allValues.in_type === 1 && x.name !== 'IGST') ||
          (allValues.in_type === 2 && !['SGST', 'CGST'].includes(x.name) && x.name.includes('GST'))
      )
      .forEach((x, index) => {
        const acId = !row
          ? trasaction.find((x) => x.t_type === 9)?.tfields?.find((y) => y?.name === x.name)
            ?.accountId
          : entry?.entry_details?.find((z) => z?.order === index + 3)?.accountId;
        const allReadyExistAccount = allValues.entry_details?.findIndex(
          (x) => x.accountId === acId
        );
        const account = allValues.entry_details.find((x, index) => x.accountId === acId);
        if (allReadyExistAccount >= 0) {
          entry_details.update(allReadyExistAccount, {
            ...allValues.entry_details[allReadyExistAccount],
            amount: x.name.trim() in total ? total[x.name.trim()] : account?.amount,
            cb:
              (x.name.trim() === 'DISCOUNT' && allValues.t_type === 9) ||
                (x.name.trim().toLowerCase().includes('gst') && allValues.t_type === 8)
                ? 2
                : 1,
            accountlabel: x.name,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, trasaction]);

  const setGST = (allValues) => {
    allValues.entry_details.forEach((x, index) => {
      if (x.order === 1) {
        allValues.entry_details[index] = {
          ...x,
          amount: allValues.tax_bi_Id === 1 ? total.BillAmount : total.ItemAmount,
          cb: allValues?.t_type === 8 ? 1 : 2,
        };
      }
      if (x.order === 2) {
        allValues.entry_details[index] = {
          ...x,
          amount: allValues.tax_bi_Id === 1 ? total.ItemAmount : total.BillAmount,
          cb: allValues?.t_type === 8 ? 2 : 1,
        };
      }
    });
    handleSubmitClick(allValues);
  };

  const handleAdd = useCallback(() => {
    inv_details?.append({
      id: null,
      productId: null,
      product: null,
      qty: null,
      rate: null,
      dprice: null,
      drate: null,
      CGST: null,
      SGST: null,
      IGST: null,
    });
    inputRefs.current = [...inputRefs.current, React.createRef()];
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [inv_details]);

  const handleRemove = useCallback(
    (index) => {
      if (index >= 0 && index !== null) {
        inv_details?.remove(index);
      }
    },
    [inv_details]
  );

  const [enterPressCount, setEnterPressCount] = useState(0);

  const HandleProductList = useCallback(async () => {
    console.log(allValues.pcategoryId, "allValues.pcategoryId", allValues.entry_details[0].accountId);
    let URL = 'automobile/products/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    if (allValues.pcategoryId) URL += `&pcategoryId=${allValues.pcategoryId}`;
    const addRecord = accountList?.find((data) => data.id === allValues.entry_details[0].accountId);
    console.log(addRecord, "addRecord");
    if (addRecord?.booking) {
      if (
        allValues.pcategoryId &&
        productCategoryList
          .find((x) => x.id === allValues?.pcategoryId)
          ?.name?.toLowerCase()
          .includes('access')
      ) {
        URL += `&accgroupmodelId=${addRecord.booking.amodelId}`;
        const { data } = await fetcher(URL);

        setProductList(data?.rows.map((x) => ({ ...x, a: 'name' })));
      }
    } else {
      const { data } = await fetcher(URL);
      setProductList(data?.rows.map((x) => ({ ...x, a: 'name' })));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.entry_details[0].accountId, productCategoryList]);

  useEffect(() => {
    if (
      !row &&
      allValues.t_type === 9 &&
      allValues.pcategoryId &&
      productCategoryList
        .find((x) => x.id === allValues?.pcategoryId)
        ?.name?.toLowerCase()
        .includes('access')
    ) {
      const pro = productList
        ?.map((x) => ({ ...x, a: 'name' }))
        .map((data) => ({
          id: null,
          productId: data?.id || null,
          product: data || null,
          productName: data?.name,
          qty: 1,
          rate:
            calculateNetAmount(
              data?.sal_rate,
              nearestDateList(data?.gst_commodity?.gst_com_details).gst_rate?.IGST
            ) || null,
          dprice: null,
          drate: null,
          CGST: null,
          SGST: null,
          IGST: null,
        }));
      setValue('inv_details', pro);
    }
  }, [allValues.pcategoryId, allValues.t_type, productCategoryList, productList, row, setValue]);

  const HandleAccountList = useCallback(async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${allValues.entry_details[0].accountMeta.page
      }&limit=${15}&status=true&creditcard=false&ac_groupId=46,47,123,124&name=${debouncedPartyAccount}&`;
    const { data } = await fetcher(URL);
    onScollerAutoComplate({
      data,
      fieldName: 'entry_details.0.accountMeta',
      meta: allValues.entry_details[0].accountMeta,
      setList: setAccountList,
      setValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPartyAccount, setValue]);

  useEffect(() => {
    HandleAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HandleAccountList, allValues.entry_details[0].accountMeta.page, debouncedPartyAccount]);

  const HandlePAccountList = useCallback(async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${allValues.entry_details[1].accountMeta.page
      }&limit=${15}&status=true&creditcard=false&ac_groupId=${va[allValues.t_type]?.ac_groupId
      }&name=${debouncedOppAccount}&`;

    const { data } = await fetcher(URL);

    onScollerAutoComplate({
      data,
      fieldName: 'entry_details.1.accountMeta',
      meta: allValues.entry_details[1].accountMeta,
      setList: setPAccountList,
      setValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.t_type, debouncedOppAccount, setValue]);

  useEffect(() => {
    HandlePAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HandlePAccountList, allValues.entry_details[1].accountMeta.page, debouncedOppAccount]);

  const HandleCreditAccountList = useCallback(async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${1}&limit=${10000}&status=true&${allValues.c_d === 3 ? 'creditcard=true' : 'ac_groupId=26'
      }&`;
    const { data } = await fetcher(URL);
    setCreditAccountList(data?.rows.map((x) => ({ ...x, a: 'name' })));
  }, [allValues.c_d]);

  useEffect(() => {
    if (allValues.c_d) {
      HandleCreditAccountList();
      HandlePAccountList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.c_d]);

  const HandleTransactionList = async () => {
    let URL = 'automobile/tsettings/';
    URL += `?page=${1}&limit=${10000}&status=true&`;
    const { data } = await fetcher(URL);
    setTrasaction(data?.rows);
  };

  useEffect(() => {
    if (
      challanData?.id &&
      allValues.entry_details.length !==
      allValues.entry_details.filter((x) => x.order <= 2).length +
      challanData.cha_taxations.filter((x) => x.order > 2).length
    ) {
      setValue(
        'stockdetails',
        challanData?.cha_details?.map((x) => ({
          ...x.stockdetail,
          id: null,
          rate: convertToRound(x.rate).toFixed(2),
          // pname: x.stockdetail.product.avariant.name,
          productId: x.stockdetail.product.id,
        }))
      );

      const eDetails = [
        ...allValues.entry_details
          .filter((x) => x.order <= 2)
          .map((x) => {
            if (x.order === 1) {
              setAccountList((pre) => [...pre, challanData?.party]);
              return {
                ...x,
                accountId: challanData?.partyId,
              };
            }
            return x;
          }),
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...challanData.cha_taxations
          ?.sort((a, b) => a.order - b.order)
          ?.filter((x) => x.order > 2)
          ?.map((x, index) => ({
            ...x,
            id: null,
            accountlabel: x?.account?.name?.split(' ')[0] || null,
          })),
      ];

      setValue('challanId', challanData?.id);
      setValue('challanNo', challanData?.v_no);
      setValue('challandate', new Date(challanData?.challandate));
      setValue('entry_details', eDetails);
    }
  }, [allValues.entry_details, challanData, setValue]);

  const handleChllanDetails = useCallback(async (id) => {
    let URL = `automobile/challans/partyId/${id}`;
    URL += `?status=true&`;
    const { data } = await fetcher(URL);
    if (data) {
      setChallanData(data);
    }
  }, []);

  const handleChllanNoDetails = useCallback(async (id) => {
    try {
      // setChallanLoading(true)
      let URL = `automobile/challans/${id}`;
      URL += `?status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setChallanData(data);
      }
      // setTimeout(() => {
      //   setChallanLoading(false);
      // }, 2000);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (
      allValues.entry_details.length &&
      allValues.entry_details.find((x) => x.order === 1)?.accountId &&
      productCategoryList.find((x) => x.id === allValues?.pcategoryId)?.name?.toLowerCase() ===
      'vehicle'
    ) {
      if (
        allValues.t_type === 9 &&
        challanData?.partyId !== allValues.entry_details.find((x) => x.order === 1)?.accountId
      )
        handleChllanDetails(allValues.entry_details.find((x) => x.order === 1)?.accountId);
    }
  }, [
    allValues.t_type,
    allValues.entry_details,
    allValues.inv_details,
    allValues?.pcategoryId,
    allValues.stockdetails,
    challanData?.partyId,
    handleChllanDetails,
    productCategoryList,
  ]);

  const debouncedChallanNo = useDebounce(allValues.challanNo || quickChallanId);

  useEffect(() => {
    if (debouncedChallanNo) handleChllanNoDetails(debouncedChallanNo);
  }, [debouncedChallanNo, handleChllanNoDetails]);

  useEffect(() => {
    if (quickChallanId) {
      setTimeout(() => {
        setValue('t_type', 9);
        setValue('pcategoryId', 3);
        setValue('tax_bi_Id', 1);
      }, 1000);
    }
  }, [quickChallanId, setValue, open]);

  useEffect(() => {
    if (quickChallanId) {
      setChallanLoading(true);
      setTimeout(() => {
        setChallanLoading(false);
      }, 2000);
    }
  }, [quickChallanId]);

  const handleSubmitClick = async (allValues) => {
    if (allValues?.tax_bi_Id === 3) {
      const unique = new Set();
      trasaction.forEach((x) => {
        x.tfields.forEach((y) => {
          unique.add(y.accountId);
        });
      });
      allValues.entry_details = allValues.entry_details.filter((x) => !unique.has(x.accountId));
    }

    const ct = Math.round(
      allValues.entry_details
        .filter((x) => x.cb === 1)
        ?.reduce((pre, next) => pre + next.amount || 0, 0)
    )?.toFixed(2);
    const dt = Math.round(
      allValues.entry_details
        .filter((x) => x.cb === 2)
        ?.reduce((pre, next) => pre + next.amount || 0, 0)
    )?.toFixed(2);

    console.log(
      allValues.entry_details.filter((x) => x.cb === 1),
      'AMIDEE',
      allValues.entry_details.filter((x) => x.cb === 2)
    );

    if (ct > 0 && dt > 0 && ct === dt) {
      setValue(
        'inv_details',
        allValues.inv_details.filter((x, index) => x.rate && x.qty)
      );
      setValue(
        'stockdetails',
        allValues.stockdetails.filter((x, index) => x.productId)
      );
      checkTranscription();
      if (isSuccessful) {
        await trigger();
        onSubmit();
      }
    } else {
      alert(`Credit ${ct} and debit ${dt} amounts should be the same.`);
    }
  };

  const checkTranscription = useCallback(() => {
    if (allValues.tax_bi_Id === 1 && [8, 9].includes(allValues.t_type)) {
      const a = ['SGST', 'CGST', 'IGST'];
      const findtr = trasaction.find((x) => x.t_type === allValues.t_type);
      if (findtr) {
        const f = findtr.tfields.filter((x) => a.includes(x.name) && x.accountId).length === 3;
        if (f) {
          setisSuccessful(true);
        } else {
          invoiceSettings?.onTrue();
          setisSuccessful(false);
        }
      } else if (row === null) {
        invoiceSettings?.onTrue();
      }
    } else {
      setisSuccessful(true);
    }
  }, [allValues.t_type, allValues.tax_bi_Id, invoiceSettings, row, trasaction]);

  const lastentry = useCallback(async () => {
    const URL = `automobile/entries/last/?t_type=${allValues.t_type}`;
    const { data } = await fetcher(URL);
    if (!entry?.id && data?.v_no)
      setValue('v_no', data?.v_no);
  }, [allValues.t_type, entry?.id, setValue]);

  useEffect(() => {
    if (allValues.t_type) {
      const findIndex = allValues.entry_details.findIndex(x => x.order === 2);
      if (allValues.t_type === 8) {
        setValue(`entry_details.${findIndex}.accountId`, 13)
      } else if (allValues.t_type === 9) {
        setValue(`entry_details.${findIndex}.accountId`, 73)
      }
      lastentry();
    }
  }, [allValues.entry_details, allValues.t_type, lastentry, setValue])

  useEffect(() => {
    if (trasaction) checkTranscription();
  }, [checkTranscription, trasaction]);

  const insuranceAgentList = useCallback(async () => {
    try {
      let URL = 'automobile/insuranceagents/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setInsuranceData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const YearList = useCallback(async () => {
    try {
      let URL = 'co_years';
      URL += `?page=${1}&limit=${10000}&status=true&companyId=${user?.companyId}&`;
      const { data } = await fetcher(URL);
      if (data) {
        setYearData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user?.companyId]);

  useEffect(() => {
    if (open) {
      HandleAccountList();
      HandleCreditAccountList();
      HandlePAccountList();
      insuranceAgentList();
      HandleTransactionList();
      HandleProductCategoryList();
      YearList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    HandleProductList();
  }, [HandleProductList]);

  useEffect(() => {
    if (invoiceSettings?.value) {
      HandleTransactionList();
    }
  }, [invoiceSettings?.value]);

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === 'a') {
      event.stopPropagation();
      if (
        allValues?.pcategoryId &&
        productCategoryList.find((x) => x.id === allValues?.pcategoryId)?.name?.toLowerCase() ===
        'vehicle'
      ) {
        handlestockdetailsAdd();
      } else handleAdd();
    }
    if (event.altKey && event.key === 'r') {
      event.stopPropagation();
      handleRemove(currentRowIndex);
    }
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const handleCloseAccount = () => {
    quickEditAccount.onFalse();
    HandleAccountList();
    HandleProductList();
  };

  const handleCloseProduct = () => {
    quickEditProduct.onFalse();
    quickEditGSTCommdity.onFalse();
    HandleProductList();
  };

  const handleCloseCredit = () => {
    quickEdit.onFalse();
    HandleCreditAccountList();
  };
  const uploadFile = async (data) => {
    try {
      setImportLoading(true);
      const URL = 'automobile/stockdetails/import/';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formdata = new FormData();
      formdata.append('file', data);
      const response = await create({ url: URL, payload: formdata, config });
      if (response) {
        setValue('stockdetails', response?.stockdetails);
        setValue('truckNo', response?.truckNo);
        setValue('transportName', response?.transportName);
        setValue('bill_no', response?.bill_no);
        setValue('b_date', new Date(response?.b_date));
        setValue('LRefNo', response?.LRefNo);
        setImportDisabled(true);
      }
      setImportLoading(false);
    } catch (error) {
      setImportLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (allValues?.b_date) document.getElementById('purchase_ac')?.focus();
  }, [allValues?.b_date]);

  const deleteProductModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/products/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleProductList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [HandleProductList, confirm, enqueueSnackbar]
  );

  const deleteAccountModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/accounts/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleAccountList();
          HandlePAccountList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [HandleAccountList, HandlePAccountList, confirm, enqueueSnackbar]
  );
  // This effect adjusts the cursor position
  useEffect(() => {
    allValues.inv_details.forEach((detail, index) => {
      const input = inputRefs?.current[index]?.current;
      if (input) {
        const { value } = input;
        if (value.endsWith('.00')) {
          const position = value.length - 3; // Position before '.00'
          input.setSelectionRange(position, position);
        }
      }
    });
  }, [allValues.inv_details]); //
  const itemTotal = allValues?.inv_details.filter((x) => x.rate);

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
        style={{ display: 'none' }}
        multiple={false}
        onChange={async (e) => {
          if (e.target.files['0']) {
            await uploadFile(e.target.files['0']);
            e.target.value = '';
          }
        }}
      />
      <Dialog fullScreen onKeyDown={handleKeyDown} open={open}>
        {importLoading || challanLoading ? (
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              sx={{ margin: 'auto', textAlign: 'center' }}
            >
              <CircularProgress />
              <br />
              Loading...
            </Grid>
          </Grid>
        ) : (
          <FormProvider methods={methods}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  top: 0,
                  zIndex: 9,
                  position: 'sticky',
                  bgcolor: (theme) => alpha(theme.palette.background.default, 1),
                }}
              >
                <Toolbar>
                  <Typography variant="h5" sx={{ flex: 1 }}>
                    {row ? `Edit Invoice` : `Add Invoice`}
                  </Typography>

                  <Grid container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} px={2}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={INVOI_TYPE?.map((option) => option.name)}
                        onBlur={(e) => {
                          if (!allValues.t_type) {
                            document.getElementById(e.target.id).focus();
                          }
                        }}
                        getOptionLabel={(option) => option}
                        value={INVOI_TYPE?.find((data) => data.id === allValues.t_type)?.name || ''}
                        openOnFocus={!quickEditAccount.value && !entry?.id && !allValues.t_type}
                        onChange={(event, newValue) => {
                          tranType = INVOI_TYPE?.find((data) => data.name === newValue)?.id;
                          setValue(
                            't_type',
                            INVOI_TYPE?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        id="invoice_type"
                        renderInput={(params) => (
                          <TextField {...params} name="t_type" label="Invoice Type" autoFocus />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                        disabled={row}
                      />
                    </Grid>

                    {quickChallanId ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} px={2}>
                        <Select
                          name="pcategoryId"
                          label="Category Name"
                          value={allValues?.pcategoryId}
                          disabled
                          onChange={(e) => {
                            setValue('pcategoryId', e.target.value);
                          }}
                          size="small"
                        >
                          {productCategoryList?.map((option) => (
                            <MenuItem key={option.value} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} px={2}>
                        <Autocomplete
                          autoHighlight={allValues.isHighligh}
                          fullWidth
                          size="small"
                          disableCloseOnSelect
                          openOnFocus
                          onInputChange={(event, newValue) => {
                            setValue('isHighligh', newValue);
                          }}
                          onBlur={() => {
                            setValue('isHighligh', '');
                          }}
                          options={
                            productCategoryList.length
                              ? productCategoryList
                              : [{ name: 'No Option', id: null, value: null }]
                          }
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setValue('pcategoryId', newValue?.id);
                            setValue('pcategory', newValue);
                          }}
                          value={allValues?.pcategory}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category Name"
                              name="pcategoryId"
                              error={errors?.pcategoryId}
                            />
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                          disabled={row}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <CompanyYear />

                  <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
                    <Iconify icon="mingcute:close-line" />
                  </IconButton>
                </Toolbar>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>

              <Grid item container xs={12} md={12} sx={{ p: 2 }} columnSpacing={10}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  alignContent="start"
                  justifyContent="space-between"
                >
                  {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={CASH_DEBIT?.map((option) => option.name)}
                      onBlur={(e) => {
                        if (!allValues.c_d && pAccountList.length) {
                          document.getElementById(e.target.id).focus();
                        }
                      }}
                      disabled={!allValues.t_type || row}
                      openOnFocus={!quickEditAccount.value}
                      getOptionLabel={(option) => option}
                      value={CASH_DEBIT?.find((data) => data.id === allValues.c_d)?.name || ''}
                      onChange={(event, newValue) => {
                        setValue('c_d', CASH_DEBIT?.find((data) => data.name === newValue)?.id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="c_d" label="Cash/Debit" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                      ListboxProps={{ ...bgColorAutocomplete }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    {(allValues?.c_d === 1 || allValues?.c_d === 3) && (
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        disableCloseOnSelect
                        options={
                          creditAccountList.length
                            ? creditAccountList
                            : [{ name: 'No Options', value: null }]
                        }
                        filterOptions={(options, state) =>
                          creditAccountList.length
                            ? creditAccountList
                            : [{ name: 'No Options', value: null }]
                        }
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        onBlur={(e) => {
                          if (!allValues.otheraccountId && !quickEdit.value) {
                            document.getElementById(e.target.id).focus();
                          }
                        }}
                        disabled={!allValues.c_d}
                        openOnFocus={!quickEdit.value}
                        onChange={(event, newValue) => {
                          setCredit(newValue);
                          setValue('otheraccountId', newValue?.id);
                        }}
                        value={
                          creditAccountList?.find((data) => data.id === allValues.otheraccountId) ||
                          entry?.otheraccount
                        }
                        componentsProps={{
                          paper: {
                            sx: {
                              width: 400,
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${allValues.c_d === 1 ? 'Cash' : 'Credit Card'} A/c.`}
                            name="otheraccountId"
                            error={errors?.otheraccountId && !allValues?.otheraccountId}
                          />
                        )}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            <GroupHeader component="li">
                              <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                              <ListItem sx={{ padding: 0, margin: 0 }}>Effect on</ListItem>
                            </GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                            <GroupFooter component="li">
                              <Button
                                onClick={() => {
                                  setCredit(null);
                                  quickEdit.onTrue();
                                }}
                              >
                                Add
                              </Button>
                              {credit && (
                                <Button
                                  onClick={() => {
                                    quickEdit.onTrue();
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </GroupFooter>
                          </li>
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                              {option?.name}
                            </ListItem>
                            <ListItem sx={{ padding: 0, margin: 0 }}>
                              {option.ac_group ? option.ac_group?.name : '-'}
                            </ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    )}
                  </Grid> */}

                  {allValues?.t_type === 9 &&
                    productCategoryList
                      .find((x) => x.id === allValues?.pcategoryId)
                      ?.name?.toLowerCase() === 'vehicle' && (
                      <>
                        <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                          <Autocomplete
                            autoHighlight
                            fullWidth
                            size="small"
                            options={
                              yearData.length ? yearData : [{ name: 'No Options', value: null }]
                            }
                            groupBy={(option) => option?.a}
                            getOptionLabel={(option) => {
                              const startDate = new Date(option.sdate);
                              const endDate = new Date(option.edate);
                              return `${startDate.getFullYear()}-${endDate.getFullYear()}`;
                            }}
                            openOnFocus
                            onChange={(event, newValue) => {
                              setValue('yearId', newValue?.id);
                            }}
                            value={(() => {
                              const addRecord = yearData?.find(
                                (data) => data.id === allValues?.yearId
                              );
                              const editRecord = entry?.godown;

                              if (addRecord) return addRecord;
                              if (editRecord) return editRecord;
                              return null;
                            })()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Year"
                                name="yearId"
                                error={errors?.yearId && !allValues?.yearId}
                                disabled
                              />
                            )}
                            disabled
                            renderOption={(props, option) => (
                              <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {`${new Date(option.sdate).getFullYear()}-${new Date(
                                    option.edate
                                  ).getFullYear()}`}
                                </ListItem>
                              </Box>
                            )}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                          <RHFTextField
                            name="challanNo"
                            label="Challan No."
                            size="small"
                            value={allValues.challanNo || ''}
                            autoFocus
                            disabled={entry?.id}
                            error={errors?.challanNo && !allValues?.challanNo}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                          <Controller
                            name="challandate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <DatePicker
                                value={new Date(field.value)}
                                timezone="Asia/Kolkata"
                                label="Challan Date"
                                onChange={(newValue) => {
                                  field.onChange(newValue);
                                }}
                                disabled
                                format="dd/MM/yyyy"
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    error: !!error,
                                    helperText: error?.message,
                                    size: 'small',
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      disableCloseOnSelect
                      options={
                        accountList.length ? accountList : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      filterOptions={(options, state) =>
                        accountList.length ? accountList : [{ name: 'No Options', value: null }]
                      }
                      onFocus={() => {
                        setaccountEditList(
                          accountList?.find(
                            (data) => data.id === allValues.entry_details[0].accountId
                          )
                        );
                        setSVBooking(
                          accountList?.find(
                            (data) => data.id === allValues.entry_details[0].accountId
                          )
                        );
                      }}
                      inputValue={allValues.entry_details[0].accountMeta.name}
                      onInputChange={(event, newInputValue) => {
                        setValue('entry_details.0.accountMeta', {
                          ...allValues.entry_details[0].accountMeta,
                          page: 1,
                          name: newInputValue,
                        });
                      }}
                      onBlur={(e) => {
                        if (
                          !allValues.entry_details.find((x) => x.order === 1)?.accountId &&
                          accountList.length &&
                          !quickEditAccount.value
                        ) {
                          document.getElementById(e.target.id).focus();
                        }
                      }}
                      disabled={
                        (allValues.c_d !== 2 ? !allValues.otheraccountId : !allValues.c_d) || row
                      }
                      openOnFocus={!quickEditAccount.value && !allValues.entry_details[0].accountId}
                      onChange={(event, newValue) => {
                        setSVBooking(newValue);
                        setaccountEditList(newValue);
                        setPAccountEditList(null);
                        setValue('gst_no', newValue?.gst_no);
                        // setValue('entry_details.0.accountId', newValue?.id);
                        entry_details?.update(0, {
                          ...allValues.entry_details[0],
                          accountId: newValue?.id,
                        });
                      }}
                      value={(() => {
                        const addRecord = accountList?.find(
                          (data) => data.id === allValues.entry_details[0].accountId
                        );
                        const editRecord = entry?.entry_details[0]?.account;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      onHighlightChange={(event, newValue) => {
                        setaccountEditList(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Party A/C."
                          name="entry_details[0].accountId"
                          error={
                            errors && entry_details.length > 0 && errors?.entry_details[0].accountId
                          }
                        />
                      )}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader component="li">
                            <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                            <ListItem
                              sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                            >
                              Balance
                            </ListItem>
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                          <GroupFooter component="li" sx={{ display: 'block' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setaccountEditList(null);
                                  setPAccountEditList(null);
                                  quickEditAccount.onTrue();
                                }}
                              >
                                Add
                              </Button>
                              {accountEditList?.id && (
                                <>
                                  <Button
                                    onClick={() => {
                                      setPAccountEditList(null);
                                      quickEditAccount.onTrue();
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      confirm.onTrue();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                color: 'black',
                                px: 2,
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={7} md={7}>
                                  {accountEditList?.address && (
                                    <>
                                      <Typography variant="body2">
                                        <strong>Address</strong>
                                      </Typography>
                                      <Typography variant="body2">
                                        {accountEditList?.address || '-'}, <br />
                                        {accountEditList?.district &&
                                          `${accountEditList?.district}, `}
                                        {accountEditList?.city?.name &&
                                          `${accountEditList?.city?.name}, `}
                                        {accountEditList?.state?.name &&
                                          `${accountEditList?.state?.name}, `}
                                        {accountEditList?.country?.name &&
                                          `${accountEditList?.country?.name}.`}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={5} md={5} sx={{ textAlign: 'left' }}>
                                  {accountEditList?.mobile && (
                                    <Typography variant="body2">
                                      Mobile No. {accountEditList?.mobile || '-'}
                                    </Typography>
                                  )}

                                  {accountEditList?.gst_no && (
                                    <Typography variant="body2">
                                      GST No. {accountEditList?.gst_no || '-'}
                                    </Typography>
                                  )}

                                  {accountEditList?.pan_no && (
                                    <Typography variant="body2">
                                      PAN No. {accountEditList?.pan_no || '-'}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </GroupFooter>
                        </li>
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                          >
                            {option?.closingBalance
                              ? fMoney.format(option?.closingBalance, { symbol: '₹ ' })
                              : '-'}
                          </ListItem>
                        </Box>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            width: 600,
                          },
                        },
                      }}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                        style: { maxHeight: '70vh' },
                        onScroll: (event) =>
                          handleScroll({
                            event,
                            allValues,
                            setValue,
                            fieldName: 'entry_details.0.accountMeta',
                            meta: allValues.entry_details[0].accountMeta,
                          }),
                      }}
                    />
                  </Grid>
                  {allValues?.pcategoryId &&
                    allValues?.t_type === 8 &&
                    productCategoryList
                      .find((x) => x.id === allValues?.pcategoryId)
                      ?.name?.toLowerCase() === 'vehicle' && (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                          <Autocomplete
                            autoHighlight
                            tabIndex={-1}
                            fullWidth
                            size="small"
                            options={
                              PURCHASE_TYPE.length
                                ? PURCHASE_TYPE
                                : [{ name: 'No Options', value: null }]
                            }
                            groupBy={(option) => option?.a}
                            getOptionLabel={(option) => option.name}
                            openOnFocus
                            onChange={(event, newValue) => {
                              setValue('in_type', newValue?.value);
                            }}
                            value={(() => {
                              const addRecord = PURCHASE_TYPE?.find(
                                (data) => data.value === allValues?.in_type
                              );
                              const editRecord = entry?.ac_group;

                              if (addRecord) return addRecord;
                              if (editRecord) return editRecord;
                              return null;
                            })()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  <span>
                                    Purchase Type <span style={{ color: 'red' }}>*</span>
                                  </span>
                                }
                                name="in_type"
                                error={errors?.in_type && !allValues?.in_type}
                                disabled
                              />
                            )}
                            disabled
                            renderOption={(props, option) => (
                              <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.name}
                                </ListItem>
                              </Box>
                            )}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                          <Autocomplete
                            autoHighlight
                            fullWidth
                            size="small"
                            options={
                              goDownData.length ? goDownData : [{ name: 'No Options', value: null }]
                            }
                            groupBy={(option) => option?.a}
                            getOptionLabel={(option) => option.name}
                            openOnFocus
                            onChange={(event, newValue) => {
                              setValue('godownId', newValue?.id);
                            }}
                            value={(() => {
                              const addRecord = goDownData?.find(
                                (data) => data.id === allValues?.godownId
                              );
                              const editRecord = entry?.godown;

                              if (addRecord) return addRecord;
                              if (editRecord) return editRecord;
                              return null;
                            })()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Location"
                                name="godownId"
                                error={errors?.godownId && !allValues?.godownId}
                              />
                            )}
                            renderOption={(props, option) => (
                              <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.name}
                                </ListItem>
                              </Box>
                            )}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Typography
                            variant="body1"
                            sx={{
                              color: 'text.primary',
                              padding: '5px',
                              textAlign: 'center',
                            }}
                          >
                            {allValues?.LRefNo ? `Load Ref. No : ${allValues?.LRefNo}` : ''}
                          </Typography>
                        </Grid>
                      </>
                    )}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                    <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={Tax_BilSupply?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          Tax_BilSupply?.find((data) => data.id === allValues.tax_bi_Id)?.name || ''
                        }
                        onBlur={(e) => {
                          if (!allValues.tax_bi_Id) {
                            document.getElementById(e.target.id).focus();
                          }
                        }}
                        disabled={!allValues.entry_details[0].accountId || row}
                        openOnFocus={!quickEditAccount.value}
                        onChange={(event, newValue) => {
                          setValue(
                            'tax_bi_Id',
                            Tax_BilSupply?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="tax_bi_Id" label="Tax/Bill of Supply" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: 200,
                            },
                          },
                        }}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                      <RHFTextField
                        name="identity"
                        label="Inv No."
                        size="small"
                        value={allValues.identity || ''}
                        disabled={!allValues.tax_bi_Id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                      <Controller
                        name="dt"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            defaultValue={new Date(field.value)}
                            timezone="Asia/Kolkata"
                            label="Voucher Date"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            disabled={!allValues.tax_bi_Id}
                            format="dd/MM/yyyy"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: allValues?.gst_no ? 'text.primary' : 'red',
                        padding: '5px',
                        textAlign: 'center',
                      }}
                    >
                      {allValues?.gst_no ? `GST IN : ${allValues?.gst_no}` : 'NILL'}
                    </Typography>
                  </Grid>
                  {allValues?.pcategoryId &&
                    allValues?.t_type === 8 &&
                    productCategoryList
                      .find((x) => x.id === allValues?.pcategoryId)
                      ?.name?.toLowerCase() === 'vehicle' && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <RHFTextField name="transportName" label="Transport Name" size="small" />
                      </Grid>
                    )}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  alignContent="start"
                >
                  {allValues?.pcategoryId &&
                    allValues?.t_type === 9 &&
                    productCategoryList
                      .find((x) => x.id === allValues?.pcategoryId)
                      ?.name?.toLowerCase() === 'vehicle' && (
                      <>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                          <RHFTextField name="covernoteno" label="Cover Not No." size="small" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                          <Controller
                            name="covernotedate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <DatePicker
                                // defaultValue={new Date(field.value)}
                                timezone="Asia/Kolkata"
                                label="C Not Date"
                                onChange={(newValue) => {
                                  field.onChange(newValue);
                                }}
                                format="dd/MM/yyyy"
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    error: !!error,
                                    helperText: error?.message,
                                    size: 'small',
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                          <Controller
                            name="covernoteexpiredate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <DatePicker
                                // defaultValue={new Date(field.value)}
                                timezone="Asia/Kolkata"
                                label="Expire C Note"
                                onChange={(newValue) => {
                                  field.onChange(newValue);
                                }}
                                format="dd/MM/yyyy"
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    error: !!error,
                                    helperText: error?.message,
                                    size: 'small',
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                          <Autocomplete
                            autoHighlight
                            fullWidth
                            size="small"
                            options={
                              SALES_TYPE.length ? SALES_TYPE : [{ name: 'No Options', value: null }]
                            }
                            groupBy={(option) => option?.a}
                            getOptionLabel={(option) => option.name}
                            openOnFocus
                            onChange={(event, newValue) => {
                              setValue('in_type', newValue?.value);
                            }}
                            value={(() => {
                              const addRecord = SALES_TYPE?.find(
                                (data) => data.value === allValues?.in_type
                              );
                              const editRecord = entry?.ac_group;

                              if (addRecord) return addRecord;
                              if (editRecord) return editRecord;
                              return null;
                            })()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Sales Type"
                                name="in_type"
                                error={errors?.in_type && !allValues?.in_type}
                                disabled
                              />
                            )}
                            disabled
                            renderOption={(props, option) => (
                              <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.name}
                                </ListItem>
                              </Box>
                            )}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                          <Autocomplete
                            autoHighlight
                            fullWidth
                            size="small"
                            options={
                              insuranceData.length
                                ? insuranceData
                                : [{ name: 'No Options', value: null }]
                            }
                            groupBy={(option) => option?.a}
                            getOptionLabel={(option) => option.name}
                            openOnFocus
                            onChange={(event, newValue) => {
                              setValue('insuranceagentId', newValue?.id);
                            }}
                            value={(() => {
                              const addRecord = insuranceData?.find(
                                (data) => data.id === allValues?.insuranceagentId
                              );
                              const editRecord = entry?.godown;

                              if (addRecord) return addRecord;
                              if (editRecord) return editRecord;
                              return null;
                            })()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Insurance Agent"
                                name="insuranceagentId"
                                error={errors?.insuranceagentId && !allValues?.insuranceagentId}
                              />
                            )}
                            renderOption={(props, option) => (
                              <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.name}
                                </ListItem>
                              </Box>
                            )}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </Grid>
                      </>
                    )}

                  {allValues?.t_type === 8 && (
                    <>
                      {' '}
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <RHFTextField
                          name="bill_no"
                          label="Bill No."
                          size="small"
                          value={allValues.bill_no || ''}
                          disabled={!allValues.tax_bi_Id}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Controller
                          name="b_date"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <DatePicker
                              defaultValue={new Date(field.value)}
                              timezone="Asia/Kolkata"
                              label="Bill Date"
                              onChange={(newValue) => {
                                field.onChange(newValue);
                              }}
                              disabled={!allValues.tax_bi_Id}
                              format="dd/MM/yyyy"
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: !!error,
                                  helperText: error?.message,
                                  size: 'small',
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                        <Button
                          onClick={() => {
                            handleAttach();
                          }}
                        >
                          <Iconify icon="solar:import-bold" />
                        </Button>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    {/* <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      disableCloseOnSelect
                      options={
                        pAccountList.length ? pAccountList : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      filterOptions={(options, state) =>
                        pAccountList.length ? pAccountList : [{ name: 'No Options', value: null }]
                      }
                      inputValue={allValues.entry_details[1].accountMeta.name}
                      onInputChange={(event, newInputValue) => {
                        setValue('entry_details.1.accountMeta', {
                          ...allValues.entry_details[1].accountMeta,
                          page: 1,
                          name: newInputValue,
                        });
                      }}
                      onBlur={(e) => {
                        if (
                          !allValues.entry_details.find((x) => x.order === 2)?.accountId &&
                          pAccountList.length &&
                          !quickEditAccount.value
                        ) {
                          document.getElementById(e.target.id).focus();
                        }
                      }}
                      onFocus={() => {
                        setPAccountEditList(
                          pAccountList?.find(
                            (data) => data.id === allValues.entry_details[1].accountId
                          )
                        );
                      }}
                      disabled={!allValues.tax_bi_Id}
                      openOnFocus={!quickEditAccount.value}
                      onChange={(event, newValue) => {
                        setPAccountEditList(newValue);
                        setaccountEditList(null);
                        setValue('entry_details.1.accountId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = pAccountList?.find(
                          (data) => data.id === allValues.entry_details[1].accountId
                        );
                        const editRecord = entry?.entry_details[1]?.account;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      id="purchase_ac"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${allValues.t_type === 8 ? 'Purchase' : 'Sale'} A/C.`}
                          name="entry_details[1].accountId"
                          error={
                            errors && entry_details.length > 0 && errors?.entry_details[1].accountId
                          }
                        />
                      )}
                      onHighlightChange={(event, newValue) => {
                        setPAccountEditList(newValue);
                      }}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader component="li">
                            <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                            <ListItem
                              sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                            >
                              Balance
                            </ListItem>
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                          <GroupFooter component="li" sx={{ display: 'block' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setaccountEditList(null);
                                  setPAccountEditList(null);
                                  quickEditAccount.onTrue();
                                }}
                              >
                                Add
                              </Button>
                              {PaccountEditList?.id && (
                                <>
                                  <Button
                                    onClick={() => {
                                      setaccountEditList(null);
                                      quickEditAccount.onTrue();
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      confirm.onTrue();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                color: 'black',
                                px: 2,
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={6} md={6}>
                                  {PaccountEditList?.address && (
                                    <>
                                      <Typography variant="body2">
                                        <strong>Address</strong>
                                      </Typography>
                                      <Typography variant="body2">
                                        {PaccountEditList?.address || '-'}
                                        {PaccountEditList?.district &&
                                          `${PaccountEditList?.district}, `}
                                        {PaccountEditList?.city?.name &&
                                          `${PaccountEditList?.city?.name}, `}
                                        {PaccountEditList?.state?.name &&
                                          `${PaccountEditList?.state?.name}, `}
                                        {PaccountEditList?.country?.name &&
                                          `${PaccountEditList?.country?.name}.`}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={6} md={6} sx={{ textAlign: 'left' }}>
                                  {PaccountEditList?.mobile && (
                                    <Typography variant="body2">
                                      Mobile No. {PaccountEditList?.mobile || '-'}
                                    </Typography>
                                  )}

                                  {PaccountEditList?.gst_no && (
                                    <Typography variant="body2">
                                      GST No. {PaccountEditList?.gst_no || '-'}
                                    </Typography>
                                  )}

                                  {PaccountEditList?.pan_no && (
                                    <Typography variant="body2">
                                      PAN No. {PaccountEditList?.pan_no || '-'}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </GroupFooter>
                        </li>
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                          >
                            {option?.closingBalance
                              ? fMoney.format(option?.closingBalance, { symbol: '₹ ' })
                              : '-'}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                        style: { maxHeight: '70vh' },
                        onScroll: (event) =>
                          handleScroll({
                            event,
                            allValues,
                            setValue,
                            fieldName: 'entry_details.1.accountMeta',
                            meta: allValues.entry_details[1].accountMeta,
                          }),
                      }}
                    /> */}
                  </Grid>
                  {allValues?.pcategoryId &&
                    allValues?.t_type !== 9 &&
                    productCategoryList
                      .find((x) => x.id === allValues?.pcategoryId)
                      ?.name?.toLowerCase() === 'vehicle' && (
                      <>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                          <RHFTextField name="truckNo" label="Truck No." size="small" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                          <RHFCheckbox name="inTras" label="In Transit" size="small" disabled />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                          <Controller
                            name="receivedDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <DatePicker
                                defaultValue={new Date(field.value)}
                                timezone="Asia/Kolkata"
                                label="Rec. Date"
                                onChange={(newValue) => {
                                  field.onChange(newValue);
                                }}
                                format="dd/MM/yyyy"
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    error: !!error,
                                    helperText: error?.message,
                                    size: 'small',
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                </Grid>
              </Grid>

              <Grid item container xs={12} md={12} sx={{ mt: 1 }}>
                {allValues?.pcategoryId &&
                  productCategoryList
                    .find((x) => x.id === allValues?.pcategoryId)
                    ?.name?.toLowerCase() === 'vehicle' && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <TableContainer
                        component={Paper}
                        sx={{
                          overflow: 'auto',
                          maxHeight: '15vh',
                          minHeight: '15vh',
                          width: '98%',
                          margin: 'auto',
                        }}
                      >
                        <Table
                          stickyHeader
                          aria-label="simple table"
                          sx={{
                            '& .MuiTableCell-sizeMedium': {
                              padding: '0px !important',
                            },
                            '& fieldset': { borderRadius: '0px', border: 'none' },
                            '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                            '& .MuiOutlinedInput-root': { padding: '0px !important' },
                            '& .autoComplete>div': { padding: '0px !important' },
                            '& .MuiTableCell-root': {
                              border: '0.01px solid #e9ecee',
                            },
                            '& .MuiTableCell-head': {
                              padding: '0px !important',
                              borderRight: '1px solid #d0d1d2 !important',
                            },
                            '& .MuiTableCell-footer': {
                              padding: '0px !important',
                            },
                            '& .MuiTableCell-head:last-child': {
                              borderRight: '0px !important',
                            },
                            '&:last-child td': {
                              borderColor: '#e9ecee !important',
                            },
                          }}
                        >
                          <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                            <TableRow>
                              <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                              <TableCell sx={{ width: 40, textAlign: 'center' }}>Code</TableCell>
                              <TableCell sx={{ width: 250, textAlign: 'center' }}>
                                Product Name
                              </TableCell>
                              <TableCell sx={{ width: 80, textAlign: 'center' }}>{allValues.t_type === 9 ? 'MRP' : 'Rate'}</TableCell>
                              <TableCell sx={{ width: 140, textAlign: 'center' }}>
                                Chassis No.
                              </TableCell>
                              <TableCell sx={{ width: 140, textAlign: 'center' }}>
                                Engine No.
                              </TableCell>
                              <TableCell sx={{ width: 200, textAlign: 'center' }}>
                                Colour Name
                              </TableCell>
                              <TableCell sx={{ width: 70, textAlign: 'center' }}>Key No.</TableCell>
                              <TableCell sx={{ width: 90, textAlign: 'center' }}>
                                Bettry No.
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: 10,
                                  textAlign: 'center',
                                  position: 'sticky',
                                  right: 0,
                                }}
                              />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {stockdetails?.fields.map((xdata, index) => (
                              <TableRow
                                key={xdata.id}
                                sx={{
                                  textAlign: 'center',
                                  position: 'sticky',
                                  left: 0,
                                  backgroundColor:
                                    allValues?.t_type === 8 &&
                                    !xdata?.instock &&
                                    xdata?.instock !== null &&
                                    '#ffbfbf',
                                }}
                              >
                                <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                <TableCell>
                                  <RHFTextField
                                    name={`stockdetails[${index}].productId`}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => {
                                      getProductById(e.target.value, index);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Autocomplete
                                    autoHighlight={
                                      allValues.stockdetails[index]?.productName?.length > 0
                                    }
                                    key={`${allValues.stockdetails[index].productId}-${index}`}
                                    onFocus={(e) => {
                                      setCurrentRowIndex(index);
                                    }}
                                    onBlur={(e) => {
                                      if (
                                        allValues.stockdetails[0]?.productId &&
                                        !quickEditProduct.value
                                      ) {
                                        setEnterPressCount((prevCount) => prevCount + 1);
                                        if (enterPressCount === 1) {
                                          document.getElementById('narration-field').focus();
                                          setEnterPressCount(0);
                                        } else if (
                                          !allValues.stockdetails[index]?.productId &&
                                          !quickEditProduct
                                        ) {
                                          document.getElementById(e.target.id).focus();
                                        }
                                        setCurrentRowIndex(null);
                                      } else if (!quickEditProduct.value) {
                                        document.getElementById(e.target.id).focus();
                                      }
                                    }}
                                    fullWidth
                                    freeSolo
                                    size="small"
                                    options={
                                      productList.length
                                        ? productList
                                        : [{ name: 'No Option', value: null }]
                                    }
                                    openOnFocus={!quickEditProduct.value}
                                    getOptionDisabled={(option) => option?.value === null}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={(() => {
                                      const fna =
                                        productList?.find(
                                          (data) =>
                                            data?.id === allValues.stockdetails[index]?.productId
                                        ) || entry?.stockdetails[index]?.product;
                                      return fna || '';
                                    })()}
                                    onInputChange={(e) => {
                                      if (e?.target?.value)
                                        setValue(
                                          `stockdetails[${index}].productName`,
                                          e.target.value
                                        );
                                    }}
                                    onChange={async (event, newValue) => {
                                      setProductEditValues(
                                        productList?.find((data) => data?.name === newValue?.name)
                                      );

                                      setEnterPressCount(0); // Reset the coun
                                      if (allValues.in_type) {
                                        setValue(
                                          `stockdetails[${index}].productId`,
                                          productList?.find((data) => data?.name === newValue?.name)
                                            ?.id
                                        );
                                      }
                                    }}
                                    groupBy={(option) => option?.a}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`stockdetails[${index}].productId`}
                                        className="autoComplete"
                                        disabled
                                      />
                                    )}
                                    disabled
                                    //  disabled={!allValues.entry_details[1].accountId > 0}
                                    renderGroup={(params) => (
                                      <li key={params.key}>
                                        <GroupHeader component="li">
                                          <ListItem sx={{ padding: 0, margin: 0 }}>
                                            Product Name
                                          </ListItem>
                                        </GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                        <GroupFooter component="li">
                                          <Button
                                            onClick={() => {
                                              quickEditProduct.onTrue();
                                              setProductEditValues(null);
                                            }}
                                          >
                                            Add
                                          </Button>

                                          {productEditValues?.id && (
                                            <>
                                              <Button
                                                onClick={() => {
                                                  quickEditProduct.onTrue();
                                                }}
                                              >
                                                Edit
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  confirm.onTrue();
                                                }}
                                              >
                                                Delete
                                              </Button>
                                            </>
                                          )}
                                        </GroupFooter>
                                      </li>
                                    )}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                          {option?.name}
                                        </ListItem>
                                      </Box>
                                    )}
                                    ListboxProps={{ ...bgColorAutocomplete }}
                                  />
                                </TableCell>
                                <TableCell sx={{ textAlign: 'end' }}>
                                  {
                                    allValues.t_type === 9 ? <RHFTextField
                                      name={`stockdetails[${index}].rate`}
                                      size="small"
                                      fullWidth
                                      variant="outlined"
                                      type="number"
                                    /> : <RHFTextField
                                      name={`stockdetails[${index}].rate`}
                                      size="small"
                                      fullWidth
                                      variant="outlined"
                                      type="number"
                                    />
                                  }

                                </TableCell>
                                <TableCell>
                                  <RHFTextField
                                    name={`stockdetails[${index}].chassisNo`}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </TableCell>

                                <TableCell>
                                  <RHFTextField
                                    name={`stockdetails[${index}].enginNo`}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </TableCell>
                                <TableCell>
                                  <Autocomplete
                                    autoHighlight
                                    fullWidth
                                    size="small"
                                    options={
                                      colourData.length
                                        ? colourData
                                        : [{ name: 'No Options', value: null }]
                                    }
                                    groupBy={(option) => option?.a}
                                    getOptionLabel={(option) => option.name}
                                    openOnFocus
                                    onChange={(event, newValue) => {
                                      setValue(`stockdetails[${index}].acolourId`, newValue?.id);
                                    }}
                                    value={(() => {
                                      const addRecord = colourData?.find(
                                        (data) =>
                                          data.id === allValues?.stockdetails[index]?.acolourId
                                      );
                                      const editRecord = entry?.ac_group;

                                      if (addRecord) return addRecord;
                                      if (editRecord) return editRecord;
                                      return null;
                                    })()}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`stockdetails[${index}].acolourId`}
                                        error={
                                          errors?.acolourId &&
                                          !allValues?.stockdetails[index]?.acolourId
                                        }
                                        disabled={importDisabled || SVBooking?.booking}
                                      />
                                    )}
                                    disabled={importDisabled || SVBooking?.booking}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        {...props}
                                        sx={{ minHeight: 'auto !important' }}
                                      >
                                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                          {option?.id}
                                        </ListItem>
                                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                          {option?.name}
                                        </ListItem>
                                      </Box>
                                    )}
                                    renderGroup={(params) => (
                                      <li key={params.key}>
                                        <GroupHeader component="li">
                                          <ListItem
                                            sx={{
                                              padding: 0,
                                              margin: 0,
                                            }}
                                          >
                                            Code
                                          </ListItem>
                                          <ListItem
                                            sx={{
                                              padding: 0,
                                              margin: 0,
                                            }}
                                          >
                                            Colour Name
                                          </ListItem>
                                        </GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                      </li>
                                    )}
                                    componentsProps={{
                                      paper: {
                                        sx: {
                                          width: 400,
                                        },
                                      },
                                    }}
                                    ListboxProps={{
                                      ...bgColorAutocomplete,
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <RHFTextField
                                    name={`stockdetails[${index}].keyNo`}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </TableCell>
                                <TableCell>
                                  <RHFTextField
                                    name={`stockdetails[${index}].batteryNo`}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </TableCell>

                                {allValues?.t_type === 8 && xdata?.instock ? (
                                  <TableCell
                                    sx={{
                                      textAlign: 'center',
                                      position: 'sticky',
                                      right: 0,
                                      index: 9,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleRemoveStockDetail(index)}
                                  >
                                    <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    sx={{
                                      textAlign: 'center',
                                      position: 'sticky',
                                      right: 0,
                                      index: 9,
                                    }}
                                  />
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={2}>
                  <TableContainer
                    component={Paper}
                    sx={{ overflow: 'auto', maxHeight: '30vh', width: '98%', margin: 'auto' }}
                  >
                    <Table
                      stickyHeader
                      aria-label="simple table"
                      sx={{
                        '& .MuiTableCell-sizeMedium': {
                          padding: '0px !important',
                        },
                        '& fieldset': { borderRadius: '0px', border: 'none' },
                        '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                        '& .MuiOutlinedInput-root': { padding: '0px !important' },
                        '& .autoComplete>div': { padding: '0px !important' },
                        '& .MuiTableCell-root': {
                          border: '0.01px solid #e9ecee',
                        },
                        '& .MuiTableCell-head': {
                          padding: '5px !important',
                          borderRight: '1px solid #d0d1d2 !important',
                        },
                        '& .MuiTableCell-footer': {
                          padding: '5px !important',
                        },
                        '& .MuiTableCell-head:last-child': {
                          borderRight: '0px !important',
                        },
                        '&:last-child td': {
                          borderColor: '#e9ecee !important',
                        },
                      }}
                    >
                      <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                        <TableRow>
                          <TableCell sx={{ width: 20, textAlign: 'center' }}>No</TableCell>
                          <TableCell sx={{ width: 400, textAlign: 'center' }}>
                            Product Name
                          </TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Unit</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Qty</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Rate</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(₹)</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(%)</TableCell>
                          {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                            <>
                              <TableCell sx={{ width: 100, textAlign: 'center' }}>
                                Gross Amount
                              </TableCell>
                              <TableCell sx={{ width: 40, textAlign: 'center' }}>GST(%)</TableCell>

                              {allValues.in_type === 1 && (
                                <>
                                  <TableCell sx={{ width: 80, textAlign: 'center' }}>
                                    CGST
                                  </TableCell>
                                  <TableCell sx={{ width: 80, textAlign: 'center' }}>
                                    SGST
                                  </TableCell>
                                </>
                              )}
                              {allValues.in_type === 2 && (
                                <TableCell sx={{ width: 80, textAlign: 'center' }}>IGST</TableCell>
                              )}
                            </>
                          )}

                          <TableCell
                            sx={{ width: 130, textAlign: 'center', position: 'sticky', right: 0 }}
                          >
                            Amount
                          </TableCell>
                          <TableCell
                            sx={{ width: 10, textAlign: 'center', position: 'sticky', right: 0 }}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inv_details?.fields?.map((field, index) => {
                          const GSTCommodity = productList.find(
                            (x) => x.id === allValues.inv_details[index]?.productId
                          )?.gst_commodity;
                          const gstRate = nearestDateList(
                            productList.find(
                              (x) => x.id === allValues.inv_details[index]?.productId
                            )?.gst_commodity?.gst_com_details
                          );
                          let DPtotalAmount = 0;
                          let DRtotalAmount = 0;
                          let GrossAmount = 0;
                          let finalTotal = 0;
                          let totalAmount = 0;
                          let CGST = 0;
                          let SGST = 0;
                          let IGST = 0;

                          if (
                            allValues.inv_details[index]?.mrp &&
                            allValues.inv_details[index]?.qty
                          ) {
                            totalAmount =
                              allValues.inv_details[index].mrp * allValues.inv_details[index].qty;
                          }
                          if (allValues.inv_details[index]?.dprice) {
                            DPtotalAmount =
                              (allValues.inv_details[index].mrp -
                                allValues.inv_details[index].dprice) *
                              allValues.inv_details[index].qty;
                          }
                          if (allValues.inv_details[index]?.drate) {
                            const rq =
                              allValues.inv_details[index].mrp * allValues.inv_details[index].qty;
                            DRtotalAmount = rq - (rq / 100) * allValues.inv_details[index].drate;
                          }

                          if (DPtotalAmount || allValues.inv_details[index]?.dprice) {
                            finalTotal = DPtotalAmount;
                          } else if (DRtotalAmount || allValues.inv_details[index]?.drate) {
                            finalTotal = DRtotalAmount;
                          } else {
                            finalTotal = totalAmount;
                          }
                          GrossAmount = finalTotal;
                          const rate = allValues.inv_details[index]?.rate || 0;
                          if (allValues?.in_type === 1 && [1, 4].includes(allValues.tax_bi_Id)) {
                            SGST = (rate / 100 || 0) * (gstRate?.gst_rate?.SGST || 0);
                            CGST = (rate / 100 || 0) * (gstRate?.gst_rate?.CGST || 0);
                            // if (allValues?.tax_bi_Id === 1) finalTotal += CGST + SGST;
                          } else if (
                            allValues?.in_type === 2 &&
                            [1, 4].includes(allValues.tax_bi_Id)
                          ) {
                            IGST = (rate / 100 || 0) * (gstRate?.gst_rate?.IGST || 0);
                            // if (allValues?.tax_bi_Id === 1) finalTotal += IGST;
                          }
                          gTotal += GrossAmount;

                          return (
                            <TableRow key={index} ref={tableBodyRef}>
                              <TableCell
                                sx={{
                                  textAlign: 'center',
                                  position: 'sticky',
                                  left: 0,
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={
                                  index >= 0 &&
                                  errors &&
                                  errors?.inv_details &&
                                  errors?.inv_details[index] &&
                                  errors?.inv_details[index]?.productId && {
                                    border: '0.01px solid #F90635 !important',
                                  }
                                }
                              >
                                <Autocomplete
                                  autoHighlight={
                                    allValues.inv_details[index]?.productName?.length > 0
                                  }
                                  key={`${field.productId}-${index}`}
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  disabled={
                                    !allValues.entry_details[1].accountId > 0 || SVBooking?.booking
                                  }
                                  onBlur={(e) => {
                                    if (
                                      allValues.inv_details[0]?.productId ||
                                      !quickEditProduct.value
                                    ) {
                                      setEnterPressCount((prevCount) => prevCount + 1);
                                      if (enterPressCount === 1) {
                                        document.getElementById('narration-field').focus();
                                        setEnterPressCount(0);
                                      } else if (
                                        !allValues.inv_details[index]?.productId &&
                                        !quickEditProduct
                                      ) {
                                        document.getElementById(e.target.id).focus();
                                      }
                                      setCurrentRowIndex(null);
                                      // eslint-disable-next-line no-dupe-else-if
                                    } else if (!quickEditProduct.value) {
                                      document.getElementById(e.target.id).focus();
                                    }
                                  }}
                                  fullWidth
                                  freeSolo
                                  size="small"
                                  options={
                                    productList.length
                                      ? productList
                                      : [{ name: 'No Option', value: null }]
                                  }
                                  openOnFocus={!quickEditProduct.value}
                                  getOptionDisabled={(option) => option?.value === null}
                                  getOptionLabel={(option) => option.name || ''}
                                  value={(() => {
                                    const fna =
                                      productList?.find(
                                        (data) =>
                                          data?.id === allValues.inv_details[index]?.productId
                                      ) || entry?.inv_details[index]?.product;

                                    return fna || '';
                                  })()}
                                  onInputChange={(e) => {
                                    if (e?.target?.value)
                                      setValue(`inv_details[${index}].productName`, e.target.value);
                                  }}
                                  onChange={async (event, newValue) => {
                                    setProductEditValues(
                                      productList?.find((data) => data?.name === newValue?.name)
                                    );

                                    setEnterPressCount(0); // Reset the coun
                                    if (allValues.in_type) {
                                      setValue(
                                        `inv_details[${index}].productId`,
                                        productList?.find((data) => data?.name === newValue?.name)
                                          ?.id
                                      );
                                    }
                                  }}
                                  groupBy={(option) => option?.a}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name={`inv_details[${index}].productId`}
                                      className="autoComplete"
                                      disabled={SVBooking?.booking}
                                    />
                                  )}
                                  renderGroup={(params) => (
                                    <li key={params.key}>
                                      <GroupHeader component="li">
                                        <ListItem sx={{ padding: 0, margin: 0 }}>
                                          Product Name
                                        </ListItem>
                                      </GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                      <GroupFooter component="li">
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                            setProductEditValues(null);
                                          }}
                                        >
                                          Add
                                        </Button>

                                        {productEditValues?.id && (
                                          <>
                                            <Button
                                              onClick={() => {
                                                quickEditProduct.onTrue();
                                              }}
                                            >
                                              Edit
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                confirm.onTrue();
                                              }}
                                            >
                                              Delete
                                            </Button>
                                          </>
                                        )}
                                      </GroupFooter>
                                    </li>
                                  )}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                        {option?.name}
                                      </ListItem>
                                    </Box>
                                  )}
                                  ListboxProps={{ ...bgColorAutocomplete }}
                                />
                              </TableCell>

                              <TableCell sx={{ textAlign: 'end' }}>
                                {productList.find(
                                  (x) => x.id === allValues.inv_details[index]?.productId
                                )?.punit?.name || ''}
                              </TableCell>

                              <TableCell
                                sx={
                                  index >= 0 &&
                                    errors &&
                                    errors?.inv_details &&
                                    errors?.inv_details[index] &&
                                    errors?.inv_details[index]?.qty
                                    ? {
                                      border: '0.01px solid #F90635 !important',
                                      textAlign: 'end',
                                    }
                                    : { textAlign: 'end' }
                                }
                              >
                                <RHFTextField
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    if (!allValues.inv_details[index]?.qty) {
                                      setValue(`inv_details[${index}].qty`, 1);
                                      document.getElementById(e.target.id).focus();
                                    }
                                    setCurrentRowIndex(null);
                                  }}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  disabled
                                  name={`inv_details[${index}].qty`}
                                  size="small"
                                  type="number"
                                  value={allValues.inv_details[index]?.qty || ''}
                                  onChange={(e) => {
                                    const qty = e.target.value >= 0 && e.target.value;
                                    setValue(`inv_details[${index}].qty`, qty);
                                  }}
                                // disabled={!(allValues.inv_details[index]?.productId > 0)}
                                />
                              </TableCell>

                              <TableCell
                                sx={
                                  index >= 0 &&
                                    errors &&
                                    errors?.inv_details &&
                                    errors?.inv_details[index] &&
                                    errors?.inv_details[index]?.rate
                                    ? {
                                      border: '0.01px solid #F90635 !important',
                                      textAlign: 'end',
                                    }
                                    : { textAlign: 'end' }
                                }
                              >
                                {
                                  allValues.t_type === 9 ? <RHFTextField
                                    inputProps={{
                                      style: { textAlign: 'right' },
                                    }}
                                    onFocus={(e) => {
                                      setCurrentRowIndex(index);
                                    }}
                                    onBlur={(e) => {
                                      if (!allValues.inv_details[index]?.productId) {
                                        document.getElementById(e.target.id).focus();
                                      }
                                      let { mrp } = allValues.inv_details[index];
                                      if (!isNaN(mrp) && mrp >= 0) {
                                        mrp = Number(mrp).toFixed(2);
                                        setValue(`inv_details[${index}].mrp`, mrp);
                                      }
                                      setCurrentRowIndex(null);
                                    }}
                                    name={`inv_details[${index}].mrp`}
                                    size="small"
                                    type="number"
                                    value={
                                      convertToRound(allValues.inv_details[index]?.mrp)?.toFixed(
                                        2
                                      ) || ''
                                    }
                                    disabled
                                    onChange={(e) => {
                                      let mrp = e.target.value;
                                      mrp = parseFloat(mrp);
                                      setValue(`inv_details[${index}].mrp`, mrp);
                                    }}
                                  /> : <RHFTextField
                                    inputProps={{
                                      style: { textAlign: 'right' },
                                    }}
                                    onFocus={(e) => {
                                      setCurrentRowIndex(index);
                                    }}
                                    onBlur={(e) => {
                                      if (!allValues.inv_details[index]?.productId) {
                                        document.getElementById(e.target.id).focus();
                                      }
                                      let { rate } = allValues.inv_details[index];
                                      if (!isNaN(rate) && rate >= 0) {
                                        rate = Number(rate).toFixed(2);
                                        setValue(`inv_details[${index}].rate`, rate);
                                      }
                                      setCurrentRowIndex(null);
                                    }}
                                    name={`inv_details[${index}].rate`}
                                    size="small"
                                    type="number"
                                    value={
                                      convertToRound(allValues.inv_details[index]?.rate)?.toFixed(
                                        2
                                      ) || ''
                                    }
                                    disabled
                                    onChange={(e) => {
                                      let rate = e.target.value;
                                      rate = parseFloat(rate);
                                      setValue(`inv_details[${index}].rate`, rate);
                                    }}
                                  />
                                }

                              </TableCell>
                              <TableCell>
                                <RHFTextField
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    setCurrentRowIndex(null);
                                  }}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  name={`inv_details[${index}].dprice`}
                                  size="small"
                                  type="number"
                                  value={
                                    parseFloat(allValues.inv_details[index]?.dprice) ||
                                    (allValues.inv_details[index]?.drate > 0 &&
                                      allValues.inv_details[index]?.mrp > 0 &&
                                      convertToRound(
                                        (parseFloat(allValues.inv_details[index]?.mrp) *
                                          parseFloat(allValues.inv_details[index]?.drate)) /
                                        100
                                      )?.toFixed(2))
                                  }
                                  onChange={(e) => {
                                    if (
                                      !isNaN(parseFloat(e.target.value)) &&
                                      !isNaN(parseFloat(allValues.inv_details[index]?.mrp))
                                    ) {
                                      const disPrice =
                                        parseFloat(e.target.value) >= 0 &&
                                        parseFloat(e.target.value) <=
                                        parseFloat(allValues.inv_details[index]?.mrp) &&
                                        parseFloat(e.target.value);
                                      setValue(
                                        `inv_details[${index}].dprice`,
                                        parseFloat(disPrice)
                                      );
                                    } else {
                                      setValue(`inv_details[${index}].dprice`, null);
                                    }
                                  }}
                                  disabled={
                                    !(allValues.inv_details[index]?.mrp > 0) ||
                                    allValues.inv_details[index]?.drate
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <RHFTextField
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    setCurrentRowIndex(null);
                                  }}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  name={`inv_details[${index}].drate`}
                                  size="small"
                                  type="number"
                                  value={
                                    parseFloat(allValues.inv_details[index]?.drate) ||
                                    (allValues.inv_details[index]?.dprice > 0 &&
                                      allValues.inv_details[index]?.mrp > 0 &&
                                      (
                                        (parseFloat(allValues.inv_details[index]?.dprice) /
                                          parseFloat(allValues.inv_details[index]?.mrp)) *
                                        100
                                      )?.toFixed(2))
                                  }
                                  onChange={(e) => {
                                    const disRate =
                                      e.target.value >= 0 &&
                                      e.target.value <= 100 &&
                                      e.target.value;
                                    setValue(`inv_details[${index}].drate`, parseFloat(disRate));
                                  }}
                                  disabled={
                                    !(allValues.inv_details[index]?.mrp > 0) ||
                                    allValues.inv_details[index]?.dprice
                                  }
                                />
                              </TableCell>

                              {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                                <>
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {GrossAmount ? GrossAmount?.toFixed(2) : ''}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: 'end' }}
                                    onClick={() => {
                                      if (allValues.inv_details[index]?.productId) {
                                        setGSTCommodity({
                                          ...GSTCommodity,
                                          productId: allValues.inv_details[index]?.productId,
                                        });
                                        quickEditGSTCommdity.setValue(true);
                                      }
                                    }}
                                  >
                                    {gstRate?.gst_rate?.IGST ? `${gstRate?.gst_rate?.IGST} %` : ''}
                                  </TableCell>
                                  {allValues.in_type === 1 && (
                                    <>
                                      <TableCell sx={{ textAlign: 'end' }}>
                                        {CGST?.toFixed(2) || ''}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'end' }}>
                                        {SGST?.toFixed(2) || ''}
                                      </TableCell>
                                    </>
                                  )}
                                  {allValues.in_type === 2 && (
                                    <TableCell sx={{ textAlign: 'end' }}>
                                      {IGST?.toFixed(2) || ''}
                                    </TableCell>
                                  )}
                                </>
                              )}

                              <TableCell
                                sx={{ textAlign: 'end', position: 'sticky', right: 0, index: 9 }}
                              >
                                {convertToRound(finalTotal).toFixed(2) || ''}
                              </TableCell>

                              {allValues.inv_details.length > 1 ? (
                                <TableCell
                                  sx={{
                                    textAlign: 'center',
                                    position: 'sticky',
                                    right: 0,
                                    index: 9,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleRemove(index)}
                                >
                                  <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                                </TableCell>
                              ) : (
                                <TableCell sx={{ textAlign: 'end' }} />
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter
                        sx={{
                          bottom: '0',
                          position: 'sticky',
                          zIndex: '11111',
                          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '15px' }}
                            colSpan={2}
                          >
                            Total Entry : {itemTotal?.length ? itemTotal?.length : '0'}
                          </TableCell>

                          <TableCell sx={{ textAlign: 'center' }} />
                          <TableCell
                            sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                          >
                            {TotalQty?.toFixed(2) || ''}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }} />
                          <TableCell
                            sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                          >
                            {TotalDPrice?.toFixed(2) || ''}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }} />
                          {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                            <>
                              <TableCell
                                sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                              >
                                {gTotal?.toFixed(2) || ''}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }} />
                              {allValues.in_type === 1 && (
                                <>
                                  <TableCell
                                    sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                                  >
                                    {total.CGST?.toFixed(2)}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                                  >
                                    {total.SGST?.toFixed(2)}
                                  </TableCell>
                                </>
                              )}
                              {allValues.in_type === 2 && (
                                <TableCell
                                  sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                                >
                                  {total.IGST?.toFixed(2)}
                                </TableCell>
                              )}
                            </>
                          )}

                          <TableCell
                            sx={{
                              textAlign: 'end',
                              position: 'sticky',
                              right: 0,
                              fontWeight: 'bold',
                              fontSize: '15px',
                            }}
                          >
                            {convertToRound(total?.TotalAmount)?.toFixed(2)}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }} />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              md={12}
              sx={{
                bottom: 60,
                zIndex: 111111,
                p: 2,
                position: 'fixed',
                justifyContent: 'space-between',
                bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
              }}
              spacing={2}
            >
              <Grid item Container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                    Narration
                  </Typography>
                  <RHFTextField id="narration-field" name="narr" size="small" multiline rows={3} />
                </Grid>
              </Grid>
              <Grid
                item
                Container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                sx={{ display: 'flex' }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} />
                <Grid
                  item
                  Container
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  sx={{ alignSelf: 'end' }}
                >
                  <Box
                    sx={{
                      maxHeight: '100px',
                      padding: '5px',
                      overflow: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px', // Adjust width of the scrollbar here
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1', // Color of the track
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888', // Color of the scrollbar itself
                        borderRadius: '10px', // Round the edges of the scrollbar
                        border: '2px solid transparent', // Optional: adds border around the scrollbar
                        backgroundClip: 'content-box', // Ensures the border doesn't overlap the background
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', // Color of the scrollbar when hovered
                      },
                    }}
                  >
                    {entry_details?.fields
                      ?.filter((x) => x.order > 2 && !x?.accountlabel?.includes('GST') && x.accountId !== 56)
                      .map((field, index) => {
                        console.log({ account: field.accountlabel, amount: field?.amount, accountId: field.accountId });
                        return (
                          <Stack direction="row" justifyContent="space-between" key={index}>
                            <Typography variant="subtitle1">
                              {field.accountlabel || field.account?.name}
                            </Typography>
                            <Typography variant="subtitle1">
                              {field?.readOnly ? (
                                field?.amount
                              ) : (
                                <RHFTextField
                                  key={index}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  name={`entry_details.${allValues.in_type === 1 ? index + 4 : index + 3
                                    }.amount`}
                                  onChange={(e) => {
                                    entry_details.update(
                                      allValues.in_type === 1 ? index + 4 : index + 3,
                                      {
                                        ...allValues.entry_details[
                                        allValues.in_type === 1 ? index + 4 : index + 3
                                        ],
                                        amount: parseFloat(e.target.value),
                                      }
                                    );
                                  }}
                                  value={
                                    allValues.entry_details[
                                      allValues.in_type === 1 ? index + 4 : index + 3
                                    ]?.amount
                                  }
                                  size="small"
                                  type="number"
                                  variant="standard"
                                />
                              )}
                            </Typography>
                          </Stack>
                        )
                      })}
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ backgroundColor: '#F3F6F8', padding: '5px' }}
                  >
                    <Typography variant="subtitle1">Bill Amount</Typography>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                        <strong>{convertToRound(total.BillAmount)?.toFixed(2)}</strong>
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Stack
              direction="row-reverse"
              alignItems="center"
              sx={{
                bottom: 0,
                zIndex: 111111,
                p: 2,
                position: 'fixed',
                justifyContent: 'space-between',
                width: '100%',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
              id="modelfooter"
            >
              <Tooltip title="(Alt + A)" arrow>
                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={() => {
                    setGST(allValues);
                  }}
                >
                  {row ? 'Update' : 'Add'}
                </LoadingButton>
              </Tooltip>

              <Tooltip title="(Esc)" arrow>
                <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Tooltip>

              <Button
                variant="soft"
                onClick={() => {
                  reset(values);
                  document.getElementById('invoice_type').focus();
                }}
                sx={{ mr: 2 }}
              >
                Reset
              </Button>

              <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
                <Tooltip title="Add (Alt+A)" arrow>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (
                        allValues?.pcategoryId &&
                        productCategoryList
                          .find((x) => x.id === allValues?.pcategoryId)
                          ?.name?.toLowerCase() === 'vehicle'
                      ) {
                        handlestockdetailsAdd();
                      } else handleAdd();
                    }}
                  >
                    <Iconify icon="mingcute:add-line" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </FormProvider>
        )}
      </Dialog>

      {quickEditAccount.value && (
        <AUserQuickEditForm
          row={accountEditList || PaccountEditList || null}
          open={quickEditAccount.value}
          onClose={handleCloseAccount}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {quickEdit.value && allValues.c_d === 3 && (
        <QuickEditForm
          row={null || credit}
          open={quickEdit.value}
          onClose={handleCloseCredit}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {quickEdit.value && allValues.c_d === 1 && (
        <AUserQuickEditForm
          row={null || credit}
          open={quickEdit.value}
          onClose={handleCloseCredit}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {quickEditProduct.value && (
        <PUserQuickEditForm
          row={productEditValues || null}
          open={quickEditProduct.value}
          onClose={handleCloseProduct}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {quickEditGSTCommdity.value && (
        <GSTCommodityQuickEditForm
          row={GSTCommodity || null}
          open={quickEditGSTCommdity.value}
          onClose={handleCloseProduct}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() =>
              (productEditValues?.id && deleteProductModel(productEditValues?.id)) ||
              (accountEditList?.id && deleteAccountModel(accountEditList?.id)) ||
              (PaccountEditList?.id && deleteAccountModel(PaccountEditList?.id))
            }
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  invoiceSettings: PropTypes.object,
  quickChallanId: PropTypes.number,
};  
