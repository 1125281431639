import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { amideepautomobilebranch, KEY, removeNonDigits, SALES_TYPE, VOUCHERTYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import {
  assignNullToEmptyOrUndefinedValues,
  convertToRound,
  fMoney,
  nearestDateList,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/automobile-accounts/account/quick-edit-form';
import Label from 'src/components/label';
import { useDebounce } from 'src/hooks/use-debounce';

const tranType = null;

export default function ProductCategoryQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [chassisNumberData, setChassisNumberData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [accountEditList, setaccountEditList] = useState({});
  const [accountNumberSearch, setAccountNumberSearch] = useState(null);

  const [countTaxation, setCountTaxation] = useState(0);
  const [partyData, setPartyData] = useState(null);
  const [discountTrue, setDiscountTrue] = useState(false);

  const [trasaction, setTrasaction] = useState([]);

  const { user } = useContext(AuthContext);

  const quickEditAccount = useBoolean();

  const bgColorAutocomplete = useBgColorAutocomplete();

  const tableBodyRef = useRef(null);

  const debouncedAccountNumber = useDebounce(accountNumberSearch);


  const VehiclePurchaseSchema = Yup.object().shape({
    challandate: Yup.date().required('challan date is required'),
    in_type: Yup.number().required('in_type is required'),
    cha_details: Yup.array().of(
      Yup.object().shape({
        stockdetailId: Yup.number().required('Chassis No. is required'),
        mrp: Yup.number().required('MRP is required'),
        rate: Yup.number().required('Net Rate is required'),
        gstamt: Yup.number().required('GST Amt is required'),
        amount: Yup.number().required('Net Amt is required'),
      })
    ),
  });

  const cyearId = user?.co_year?.id;

  const values = useMemo(
    () => ({
      yearId: entry?.yearId || cyearId,
      identity: entry?.identity ? `${amideepautomobilebranch[entry.branchId]} ${entry?.identity}` : '',
      partycode: entry?.party?.identity || '',
      party: entry?.party || null,
      challandate: entry?.challandate || new Date(),
      pcategoryId: 1,
      tax_bi_Id: 1,
      partyId: entry?.partyId || null,
      covernoteno: entry?.covernoteno || '',
      covernotedate: entry?.covernotedate || null,
      covernoteexpiredate: entry?.covernoteexpiredate || null,
      insuranceagentId: entry?.insuranceagentId || null,
      in_type: entry?.in_type || 3,
      cha_details:
        entry?.cha_details?.map((xdata) => ({
          id: xdata?.id || null,
          identity: xdata?.identity || '',
          chassisNumber: xdata?.stockdetail?.chassisNumber || '',
          chassis: xdata?.stockdetail || null,
          stockdetailId: xdata?.stockdetailId || null,
          stockdetail: xdata?.stockdetail,
          enginNo: xdata?.stockdetail?.enginNo || '',
          color: xdata?.stockdetail?.acolour?.name || '',
          mrp: xdata?.mrp || null,
          discount: xdata?.discount || null,
          // discount: xdata?.discount ? Math.round(Number(xdata?.discount) * 128) / 100 : null,
          rate: xdata?.rate || null,
          gstamt: xdata?.gstamt || null,
          amount: xdata?.amount || null,
        })) ||
        [...Array(1)].map(() => ({
          id: null,
          identity: '',
          chassisNumber: '',
          chassis: null,
          enginNo: '',
          color: '',
          stockdetail: {
            batteryNo: '',
          },
          mrp: null,
          discount: null,
          rate: null,
          gstamt: null,
          amount: null,
        })),
      cha_taxations: (entry?.cha_taxations &&
        Array.isArray(entry?.cha_taxations) &&
        entry?.cha_taxations
          ?.sort((a, b) => a.order - b.order)
          ?.map((x) => {
            const cgstId = trasaction[tranType]?.tfields
              .filter((x) => ['CGST', 'SGST', 'IGST'].includes(x.name))
              ?.map((x) => x.accountId);
            if (cgstId?.includes(x.accountId)) {
              if (
                trasaction[tranType]?.tfields.find((x) => x.name === 'IGST')?.accountId ===
                x.accountId
              ) {
                x.in_type = 2;
              } else {
                x.in_type = 1;
              }
            }
            return {
              ...(x ? { id: x.id } : {}),
              ...(x.in_type ? { in_type: x?.in_type } : {}),
              cb: x?.cb || 1,
              cd: x?.cd || 1,
              accountId: x?.accountId || null,
              account: x?.account || null,
              entryId: x?.entryId || null,
              bentryId: x?.bentryId || null,
              entry_detailId: x?.entry_detailId || null,
              bpoptionId: x?.bpoptionId || null,
              ac_no: x?.ac_no || null,
              amount: x?.amount || null,
              order: x?.order || null,
              accountlabel: `${x?.account?.s_n}` || null,
              accountMeta: {
                page: 1,
                name: '',
                totalPage: 1,
                editRow: null,
              },
            };
          })) || [
          {
            cb: 1,
            cd: 1,
            accountId: null,
            entryId: null,
            bentryId: null,
            entry_detailId: null,
            bpoptionId: null,
            ac_no: null,
            amount: null,
            order: 1,
            account: null,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          },
          {
            cb: 1,
            cd: 1,
            accountId: null,
            entryId: null,
            bentryId: null,
            entry_detailId: null,
            bpoptionId: null,
            ac_no: null,
            amount: null,
            order: 2,
            account: null,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          },
        ],
      subtotal: entry?.subtotal || 0,
      billamount: entry?.billamount || 0,
    }),
    [entry, trasaction, cyearId]
  );

  const methods = useForm({
    resolver: yupResolver(VehiclePurchaseSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const GST_NUMBER = user?.companies?.find((x) => x.id === user?.companyId)?.gst_no;
  const GST_Company = GST_NUMBER?.substring(0, 2);
  const GST_PARTY = allValues?.gst_no?.substring(0, 2);

  const CStateId = user?.companies?.find((x) => x.id === user?.companyId)?.stateId;
  const stateId = partyData?.stateId;

  useEffect(() => {
    if (typeof stateId === "number" && typeof CStateId === "number") {
      if (stateId === CStateId) {
        setValue('in_type', 1);
      } else {
        setValue('in_type', 2);
      }
    } else setValue('in_type', 1);
  }, [
    GST_Company,
    row,
    GST_NUMBER,
    GST_PARTY,
    allValues.gst_no,
    allValues.cha_taxations,
    setValue,
    stateId,
    CStateId,
    allValues.purchaseType,
  ]);

  const cha_details = useFieldArray({
    control,
    name: 'cha_details',
  });

  const cha_taxations = useFieldArray({
    control,
    name: 'cha_taxations',
  });

  useEffect(() => {

    const restTotal = cha_taxations.fields.filter(x => allValues.in_type === 1 ? x.order > 4 : x.order > 3).reduce((pre, next) => {
      if (
        [
          'TCS',
          'INSURANCE',
          'RTO',
          'HPA',
          'ROAD TAX',
          'R.T.O.',
          'INSURANCE',
          'RTO AGF',
          'EXT.WARR.',
          'H.P.A',
          'EXTENDED_WARRANTY',
          'COATING',
          'ROADTAX',
          'RTO AGENT FEE',
          'OTHER',
          'DISCOUNT',
          'DISCOUNTTRADING'
        ].includes(next?.accountlabel?.trim())
      ) {
        if (next.accountlabel === 'DISCOUNTTRADING' || next.accountlabel === 'DISCOUNT') pre -= parseFloat(next.amount) || 0;
        else pre += parseFloat(next.amount) || 0;
      }

      return pre;
    }, 0);
    const bAMount = restTotal + parseFloat(allValues.subtotal);

    setValue('billamount', bAMount);
  }, [allValues.in_type, allValues.subtotal, cha_taxations.fields, setValue]);

  useEffect(() => {
    const list = allValues.cha_details?.filter((x) => x?.stockdetail?.product?.id);

    const mainGSTTotal = list.reduce(
      (pre, next) =>
        pre + (next.stockdetail.product.avariant
          ? next.stockdetail.product.avariant.price.sgst
          : 0) * 2,
      0
    );
    const extendwarranty = list.reduce(
      (pre, next) =>
        pre + next.stockdetail.product.avariant
          ? next.stockdetail.product.avariant.price.extwar
          : 0,
      0
    );
    const ROADTAXTotal = list.reduce(
      (pre, next) =>
        pre + next.stockdetail.product.avariant
          ? next.stockdetail.product.avariant.price.roadtax
          : 0,
      0
    );
    const HPCHARGES = list.reduce(
      (pre, next) =>
        pre + next.stockdetail.product.avariant
          ? next.stockdetail.product.avariant.price.hpcharges
          : 0,
      0
    );
    const RTOTotal = list.reduce(
      (pre, next) =>
        pre + next.stockdetail.product.avariant ? next.stockdetail.product.avariant.price.rto : 0,
      0
    );
    const insuranceTOTAL = list.reduce(
      (pre, next) =>
        pre + next.stockdetail.product.avariant
          ? next.stockdetail.product.avariant.price.insurance
          : 0,
      0
    );
    const otherTOTAL = list.reduce(
      (pre, next) =>
        pre + next.stockdetail.product.avariant
          ? next.stockdetail.product.avariant.price.others
          : 0,
      0
    );

    const calculate = (name) => {
      switch (name) {
        case 'CGST':
          return mainGSTTotal / 2;
        case 'SGST':
          return mainGSTTotal / 2;
        case 'IGST':
          return mainGSTTotal;
        case 'INSURANCE':
          return insuranceTOTAL;
        case 'RTO':
          return RTOTotal;
        case 'HPA':
          return HPCHARGES;
        case 'RTO AGENT FEE':
          return otherTOTAL;
        case 'ROADTAX':
          return ROADTAXTotal;
        case 'EXTENDED_WARRANTY':
          return extendwarranty;
        default:
          return 0;
      }
    };

    if (row === null || !('id' in row))
      trasaction
        .find((x) => x.t_type === 9)
        ?.tfields?.sort((a, b) => a.seq - b.seq)
        ?.filter(
          (x) =>
            (allValues.in_type === 1 && x.name !== 'IGST') ||
            (allValues.in_type === 2 && !['SGST', 'CGST'].includes(x.name))
        )
        .filter((y) => {
          if (y.name.trim().toLowerCase() !== 'hpa') return true;
          if (y.name.trim().toLowerCase() === 'hpa' && partyData?.booking?.hypo === true)
            return true;
          return false;
        })
        .forEach((x, index) => {
          const acId = !row
            ? trasaction.find((x) => x.t_type === 9)?.tfields?.find((y) => y?.name === x.name)
              ?.accountId
            : entry?.cha_taxations?.find((z) => z?.order === index + 3)?.accountId;
          const allReadyExistAccount = allValues.cha_taxations?.findIndex(
            (x) => x.accountId === acId
          );
          if (
            allReadyExistAccount >= 0 &&
            JSON.stringify(allValues.cha_taxations[allReadyExistAccount])
          ) {
            cha_taxations.update(allReadyExistAccount, {
              ...allValues.cha_taxations[allReadyExistAccount],
              amount: calculate(x.name.trim()),
              cb: 1,
              accountlabel: x.name,
            });
          } else {
            cha_taxations.append({
              cb: 1,
              accountId: acId,
              entryId: null,
              bentryId: null,
              entry_detailId: null,
              bpoptionId: null,
              ac_no: null,
              amount: calculate(x.name.trim()),
              order: index + 3,
              readOnly: false,
              in_type: allValues.in_type,
              accountlabel: x.name,
            });
          }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.in_type, countTaxation, row, trasaction]);
  const lastentry = useCallback(async () => {
    const URL = `automobile/challans/last/`;
    const { data } = await fetcher(URL);
    if (data?.identity && allValues.identity === null)
      setValue('identity', data?.identity);
  }, [allValues.identity, setValue]);

  const handleAdd = useCallback(() => {
    cha_details?.append({
      id: null,
      identity: null,
      chassisNumber: '',
      chassis: null,
      enginNo: '',
      color: '',
      stockdetail: {
        batteryNo: '',
      },
      mrp: null,
      discount: null,
      rate: null,
      gstamt: null,
      amount: null,
    });
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [cha_details]);

  const handleRemoveStockDetail = useCallback(
    (index) => {
      cha_details.remove(index);
    },
    [cha_details]
  );

  const YearList = useCallback(async () => {
    try {
      let URL = 'co_years';
      URL += `?page=${1}&limit=${10000}&status=true&companyId=${user?.companyId}&`;
      const { data } = await fetcher(URL);
      if (data) {
        setYearData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user?.companyId]);

  const partyAccountList = useCallback(async (accNumner) => {
    try {
      let URL = 'automobile/accounts/';
      URL += `?page=${1}&limit=${5}&status=true&`;
      if (accNumner) URL += `name=${accNumner}&`;

      const { data } = await fetcher(URL);
      if (data) {
        if (data?.rows.length) {
          const party = data?.rows.find(x => x.identity === parseInt(accNumner, 10));
          setPartyData(party)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    partyAccountList(debouncedAccountNumber);
  }, [debouncedAccountNumber, partyAccountList]);

  useEffect(() => {
    lastentry();
  }, [lastentry])
  const chassisNumberList = useCallback(async (acId, avId) => {
    try {
      let URL = 'automobile/stockdetails/';
      URL += `?page=${1}&limit=${100}&status=true&inStock=true&`;
      if (avId) URL += `productId=${avId}&`;
      if (acId) URL += `acolourId=${acId}&`;

      const { data } = await fetcher(URL);
      if (data) {
        setChassisNumberData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (partyData?.booking?.acolourId || partyData?.booking?.avariantId) {
      chassisNumberList(partyData?.booking?.acolourId, partyData?.booking?.avariantId);
    }
  }, [
    partyData?.booking?.acolourId,
    partyData?.booking?.avariantId,
    chassisNumberList,
  ]);

  const insuranceAgentList = useCallback(async () => {
    try {
      let URL = 'automobile/insuranceagents/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setInsuranceData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onSubmit = handleSubmit(async (payload) => {
    payload.cha_details = payload.cha_details.filter((x) => x.avariantId !== null);
    payload.cha_taxations = payload.cha_taxations.filter((x) => x.accountId !== null);
    payload = assignNullToEmptyOrUndefinedValues(payload);

    payload = {
      ...payload,
      identity: removeNonDigits(payload.identity),
      billamount: convertToRound(payload.billamount),
    };

    setLoading(true);
    try {
      let URL = '/automobile/challans/';
      if (entry?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      onClose();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const HandleTransactionList = async () => {
    let URL = 'automobile/tsettings/';
    URL += `?page=${1}&limit=${10000}&status=true&`;
    const { data } = await fetcher(URL);
    setTrasaction(data?.rows);
  };

  useEffect(() => {
    reset();
    YearList();
    insuranceAgentList();
    HandleTransactionList();
  }, [YearList, insuranceAgentList, open, reset]);

  useEffect(() => {
    if (discountTrue) {
      const netRate =
        (allValues?.cha_details[0]?.chassis?.product?.avariant?.price?.dealprice ?? 0) +
        (allValues?.cha_details[0]?.chassis?.product?.avariant?.price?.hcharge ?? 0);

      // eslint-disable-next-line no-unsafe-optional-chaining
      const discountOld = (allValues?.cha_details[0]?.discount / 128) * 100 || 0;

      const disnetRate = netRate - discountOld || 0;

      const CGST_SGST = (disnetRate / 100) * 28;
      setValue(`cha_details[0].rate`, disnetRate.toFixed(2));
      setValue(`cha_details[0].gstamt`, CGST_SGST.toFixed(2));

      const mainamt = disnetRate + CGST_SGST;
      setValue(`cha_details[0].amount`, mainamt.toFixed(2));

      setValue(`subtotal`, mainamt.toFixed(2));
      setDiscountTrue(false);
    }
  }, [allValues?.cha_details, discountTrue, setValue]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    if (partyData?.booking?.others) {
      partyData.booking.others.forEach((item) => {
        if ([1, 3, 9].includes(item.t_type)) totalAmount -= item.amount || 0;
        else totalAmount += item.amount || 0;
      });
    }
    return totalAmount;
  };

  const totalAmount = calculateTotalAmount();

  const BillMainAMount = parseFloat(totalAmount) - parseFloat(allValues?.billamount);
  const GETBYID = async (id) => {
    try {
      let URL = `automobile/challans/${id}`;
      URL += `?status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setEntry(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GETBYID(row?.id);
  }, [row])
  return (
    <>
      <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {row ? 'Update Delivery Challan' : 'Add Delivery Challan'}
                </Typography>
                <IconButton color="inherit" edge="start" onClick={onClose} tabIndex="-1">
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <DialogContent sx={{ p: 2 }}>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={8}>
                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                  <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={yearData.length ? yearData : [{ name: 'No Options', value: null }]}
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => {
                        const startDate = new Date(option.sdate);
                        const endDate = new Date(option.edate);
                        return `${startDate.getFullYear()}-${endDate.getFullYear()}`;
                      }}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('yearId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = yearData?.find((data) => data.id === allValues?.yearId);
                        const editRecord = entry?.godown;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Year"
                          name="yearId"
                          error={errors?.yearId && !allValues?.yearId}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {`${new Date(option.sdate).getFullYear()}-${new Date(
                              option.edate
                            ).getFullYear()}`}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                    <RHFTextField
                      name="identity"
                      label="Challan No."
                      size="small"
                      autoFocus
                      disabled={entry?.id}
                      error={errors?.identity && !allValues?.identity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Controller
                      name="challandate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Challan Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          error={errors?.challandate && !allValues?.challandate}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                          disabled={entry?.id}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <TextField
                      name="partycode"
                      label="Party Code."
                      size="small"
                      value={allValues.partycode || ''}
                      onChange={(e) => {
                        const partyId = removeNonDigits(e.target.value)
                        setAccountNumberSearch(partyId);
                        setValue('partycode', partyId)
                      }}
                      error={errors?.partycode && !allValues?.partycode}
                    />

                    {/* <Autocomplete
                      key="id"
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        accountsList.length ? accountsList : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setValue('partyId', newValue?.id);
                        setpartyData(newValue);
                        setPartyData(newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        setAccountNumberSearch(newInputValue);
                      }}
                      filterOptions={(options, state) => {
                        if (accountsList.length) {
                          console.log(accountsList, 'accountList', state.inputValue.toLowerCase());
                          const filtered = accountsList.filter(
                            (x) =>
                              x.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                              x.identity?.toString().includes(state.inputValue)
                          );
                          return filtered.length
                            ? filtered
                            : [{ name: 'No matching options', value: null }];
                        }
                        return [{ name: 'No Options', value: null }];
                      }}
                      onHighlightChange={(event, newValue) => {
                        setaccountEditList(newValue);
                      }}
                      value={partyData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Select Account <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          name="partyId"
                          error={errors?.partyId && !allValues?.partyId}
                          disabled={entry?.id}
                        />
                      )}
                      disabled={entry?.id}
                      renderGroup={(params) => (
                        <li key={`${params.group}-${params.key}`}>
                          <GroupHeader component="li">
                            <ListItem sx={{ padding: 0, margin: 0, width: '30%' }}>Code</ListItem>
                            <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                          <GroupFooter component="li" sx={{ display: 'block' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setaccountEditList(null);
                                  quickEditAccount.onTrue();
                                }}
                              >
                                Add
                              </Button>
                              {accountEditList?.id && (
                                <Button
                                  onClick={() => {
                                    quickEditAccount.onTrue();
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                color: 'black',
                                px: 2,
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={7} md={7}>
                                  {accountEditList?.address && (
                                    <>
                                      <Typography variant="body2">
                                        <strong>Address</strong>
                                      </Typography>
                                      <Typography variant="body2">
                                        {accountEditList?.address || '-'}, <br />
                                        {accountEditList?.district &&
                                          `${accountEditList?.district}, `}
                                        {accountEditList?.city?.name &&
                                          `${accountEditList?.city?.name}, `}
                                        {accountEditList?.state?.name &&
                                          `${accountEditList?.state?.name}, `}
                                        {accountEditList?.country?.name &&
                                          `${accountEditList?.country?.name}.`}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={5} md={5} sx={{ textAlign: 'left' }}>
                                  {accountEditList?.mobile && (
                                    <Typography variant="body2">
                                      Mobile No. {accountEditList?.mobile || '-'}
                                    </Typography>
                                  )}

                                  {accountEditList?.gst_no && (
                                    <Typography variant="body2">
                                      GST No. {accountEditList?.gst_no || '-'}
                                    </Typography>
                                  )}

                                  {accountEditList?.pan_no && (
                                    <Typography variant="body2">
                                      PAN No. {accountEditList?.pan_no || '-'}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </GroupFooter>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            width: 600,
                          },
                        },
                      }}
                      renderOption={(props, option, index) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={index} sx={{ padding: 0, margin: 0, width: '30%' }}>
                            {option?.identity}
                          </ListItem>
                          <ListItem key={`${index}-label`} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    {partyData && <>
                      <Typography variant="body2">
                        <strong>{partyData?.party?.name}</strong>
                      </Typography>
                      <Typography variant="body2">
                        {partyData?.address || '-'}, <br />
                        {partyData?.district &&
                          `${partyData?.district}, `}
                        {partyData?.city?.name &&
                          `${partyData?.city?.name}, `}
                        {partyData?.state?.name &&
                          `${partyData?.state?.name}, `}
                        {partyData?.country?.name &&
                          `${partyData?.country?.name}.`}
                      </Typography>
                    </>}
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    sx={{ justifyContent: 'space-between', display: 'flex' }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {partyData?.booking?.avariant?.id && (
                        <Label>{partyData?.booking?.avariant?.id}</Label>
                      )}
                      &nbsp;
                      {partyData?.booking?.avariant?.name}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {partyData?.booking?.acolour?.id && (
                        <Label>{partyData?.booking?.acolour?.id}</Label>
                      )}
                      &nbsp;
                      {partyData?.booking?.acolour?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    sx={{ justifyContent: 'space-between', display: 'flex' }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {partyData?.booking?.afinance?.name}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {partyData?.booking?.financebranch?.name}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {partyData?.booking?.institute?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <RHFTextField name="covernoteno" label="Cover Not No." size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Controller
                      name="covernotedate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          // defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="C Not Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Controller
                      name="covernoteexpiredate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          // defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Expire C Note"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        SALES_TYPE.length ? SALES_TYPE : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('in_type', newValue?.value);
                      }}
                      value={(() => {
                        const addRecord = SALES_TYPE?.find(
                          (data) => data.value === allValues?.in_type
                        );
                        return addRecord;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sales Type"
                          name="in_type"
                          error={errors?.in_type && !allValues?.in_type}
                          disabled={entry?.id}
                        />
                      )}
                      disabled={entry?.id}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        insuranceData.length ? insuranceData : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('insuranceagentId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = insuranceData?.find(
                          (data) => data.id === allValues?.insuranceagentId
                        );
                        const editRecord = entry?.godown;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Insurance Agent"
                          name="insuranceagentId"
                          error={errors?.insuranceagentId && !allValues?.insuranceagentId}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ mt: 1 }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    overflow: 'auto',
                    width: '100%',
                    margin: 'auto',
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{
                      '& .MuiTableCell-sizeMedium': {
                        padding: '0px !important',
                      },
                      '& fieldset': { borderRadius: '0px', border: 'none' },
                      '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                      '& .MuiOutlinedInput-root': { padding: '0px !important' },
                      '& .autoComplete>div': { padding: '0px !important' },
                      '& .MuiTableCell-root': {
                        border: '0.01px solid #e9ecee',
                      },
                      '& .MuiTableCell-head': {
                        padding: '0px !important',
                        borderRight: '1px solid #d0d1d2 !important',
                      },
                      '& .MuiTableCell-footer': {
                        padding: '0px !important',
                      },
                      '& .MuiTableCell-head:last-child': {
                        borderRight: '0px !important',
                      },
                      '&:last-child td': {
                        borderColor: '#e9ecee !important',
                      },
                    }}
                  >
                    <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                      <TableRow>
                        <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                        <TableCell sx={{ width: 200, textAlign: 'center' }}>Chassis No.</TableCell>
                        <TableCell sx={{ width: 150, textAlign: 'center' }}>Engine No</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Color</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Battery No</TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>MRP</TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>Disc</TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>Net Rate</TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>GST Amt</TableCell>
                        <TableCell sx={{ width: 100, textAlign: 'center' }}>Net Amt</TableCell>
                        <TableCell
                          sx={{ width: 10, textAlign: 'center', position: 'sticky', right: 0 }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cha_details?.fields.map((xdata, index) => (
                        <TableRow
                          key={xdata.id}
                          sx={{
                            textAlign: 'center',
                            position: 'sticky',
                            left: 0,
                          }}
                        >
                          <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                          <TableCell>
                            <Autocomplete
                              autoHighlight
                              fullWidth
                              size="small"
                              options={
                                chassisNumberData?.length
                                  ? chassisNumberData.filter(
                                    (x) =>
                                      !allValues?.cha_details
                                        ?.map((y) => y.chassisNumber)
                                        .includes(x.chassisNo)
                                  )
                                  : [{ name: 'No Options', value: null }]
                              }
                              groupBy={(option) => option?.a}
                              getOptionLabel={(option) => option.chassisNo}
                              openOnFocus
                              onBlur={() => {
                                setCountTaxation((pre) => pre + 1);
                              }}
                              onChange={(event, newValue) => {
                                setValue(`cha_details[${index}].stockdetailId`, newValue?.id);
                                setValue(
                                  `cha_details[${index}].chassisNumber`,
                                  newValue?.chassisNo
                                );
                                setValue(`cha_details[${index}].stockdetail`, newValue);
                                setValue(`cha_details[${index}].chassis`, newValue);
                                setValue(`cha_details[${index}].enginNo`, newValue?.enginNo);
                                setValue(`cha_details[${index}].color`, newValue?.acolour?.name);
                                setValue(
                                  `cha_details[${index}].mrp`,
                                  convertToRound(
                                    newValue?.product?.avariant?.price?.exshowroomprice
                                  )
                                );

                                const netRate =
                                  (newValue?.product?.avariant?.price?.dealprice ?? 0) +
                                  (newValue?.product?.avariant?.price?.hcharge ?? 0);
                                setValue(`cha_details[${index}].rate`, netRate.toFixed(2));

                                const CGST_SGST = (netRate / 100) * 28;
                                setValue(`cha_details[${index}].gstamt`, CGST_SGST.toFixed(2));

                                const mainamt = netRate + CGST_SGST;
                                setValue(`cha_details[${index}].amount`, mainamt.toFixed(2));

                                setValue(`subtotal`, mainamt.toFixed(2));
                              }}
                              value={allValues?.cha_details[index]?.chassis}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`cha_details[${index}].chassisNumber`}
                                  disabled={entry?.id}
                                />
                              )}
                              disabled={entry?.id}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Chassis No
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Engine No
                                    </ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                </li>
                              )}
                              componentsProps={{
                                paper: {
                                  sx: {
                                    width: 600,
                                  },
                                },
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  {...props}
                                  sx={{ minHeight: 'auto !important' }}
                                >
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.chassisNo}
                                  </ListItem>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.enginNo}
                                  </ListItem>
                                </Box>
                              )}
                              ListboxProps={{
                                ...bgColorAutocomplete,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].enginNo`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              disabled
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].color`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              disabled
                            />
                          </TableCell>

                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].stockdetail.batteryNo`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              id="battery_no"
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].mrp`}
                              size="small"
                              fullWidth
                              value={
                                allValues.cha_details[index].mrp
                                  ? Math.round(allValues.cha_details[index].mrp).toFixed(2)
                                  : ''
                              }
                              variant="outlined"
                              disabled
                              type="number"
                              inputProps={{
                                style: { textAlign: 'right' },
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].discount`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: { textAlign: 'right' },
                              }}
                              // disabled={entry?.id}
                              onBlur={() => {
                                console.log('LOG AMIDEEP');
                                setDiscountTrue(true);
                              }}
                            // onChange={(e) => {
                            // const netRate =
                            //   (allValues?.cha_details[index]?.chassis?.product?.avariant?.price
                            //     ?.dealprice ?? 0) +
                            //   (allValues?.cha_details[index]?.chassis?.product?.avariant?.price
                            //     ?.hcharge ?? 0);

                            // const discountOld = (e.target.value / 128) * 100 || 0;

                            // const disnetRate = netRate - discountOld;

                            // const CGST_SGST = (disnetRate / 100) * 28;
                            // setValue(`cha_details[0].discount`, discountOld.toFixed(2));
                            // setValue(`cha_details[${index}].rate`, disnetRate.toFixed(2));
                            // setValue(`cha_details[${index}].gstamt`, CGST_SGST.toFixed(2));

                            // const mainamt = disnetRate + CGST_SGST;
                            // setValue(`cha_details[${index}].amount`, mainamt.toFixed(2));

                            // setValue(`subtotal`, mainamt.toFixed(2));
                            // }}
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].rate`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              disabled
                              value={
                                allValues.cha_details[index].rate
                                  ? Math.round(allValues.cha_details[index].rate)?.toFixed(2)
                                  : ''
                              }
                              inputProps={{
                                style: { textAlign: 'right' },
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].gstamt`}
                              size="small"
                              fullWidth
                              value={
                                allValues.cha_details[index].gstamt
                                  ? Math.round(allValues.cha_details[index].gstamt).toFixed(2)
                                  : ''
                              }
                              variant="outlined"
                              disabled
                              inputProps={{
                                style: { textAlign: 'right' },
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`cha_details[${index}].amount`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={
                                allValues.cha_details[index].amount
                                  ? Math.round(allValues.cha_details[index].amount)?.toFixed(2)
                                  : ''
                              }
                              disabled
                              inputProps={{
                                style: { textAlign: 'right' },
                              }}
                            />
                          </TableCell>

                          {index !== 0 ? (
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                position: 'sticky',
                                right: 0,
                                index: 9,
                                cursor: 'pointer',
                              }}
                              onClick={() => handleRemoveStockDetail(index)}
                            >
                              <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                            </TableCell>
                          ) : (
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                position: 'sticky',
                                right: 0,
                                index: 9,
                              }}
                            />
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid
                item
                container
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                sx={{ mt: 1, alignItems: 'end', bottom: 60, position: 'absolute' }}
              >
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
                  <TableContainer
                    component={Paper}
                    sx={{ overflow: 'auto', maxHeight: '30vh', width: '100%', margin: 'auto' }}
                  >
                    <Table
                      stickyHeader
                      aria-label="simple table"
                      sx={{
                        '& .MuiTableCell-sizeMedium': {
                          padding: '0px !important',
                        },
                        '& fieldset': { borderRadius: '0px', border: 'none' },
                        '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                        '& .MuiOutlinedInput-root': { padding: '0px !important' },
                        '& .autoComplete>div': { padding: '0px !important' },
                        '& .MuiTableCell-root': {
                          border: '0.01px solid #e9ecee',
                        },
                        '& .MuiTableCell-head': {
                          padding: '0px !important',
                          borderRight: '1px solid #d0d1d2 !important',
                        },
                        '& .MuiTableCell-footer': {
                          padding: '0px !important',
                        },
                        '& .MuiTableCell-head:last-child': {
                          borderRight: '0px !important',
                        },
                        '&:last-child td': {
                          borderColor: '#e9ecee !important',
                        },
                      }}
                    >
                      <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                        <TableRow>
                          <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                          <TableCell sx={{ width: 40, textAlign: 'center' }}>Date</TableCell>
                          <TableCell sx={{ width: 140, textAlign: 'center' }}>Type</TableCell>
                          <TableCell sx={{ width: 80, textAlign: 'center' }}>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {partyData?.booking?.others ? (
                          partyData?.booking?.others
                            ?.sort((a, b) => a.t_type - b.t_type)
                            ?.map((x) => (
                              <TableRow>
                                <TableCell sx={{ textAlign: 'center' }}>{x.v_no}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{x.dt}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                  <Typography variant="body2">
                                    {x.t_type === 9 && x?.pcategory?.name.toUpperCase()}{' '}
                                    {VOUCHERTYPE.find(
                                      (data) => data.value === x.t_type
                                    )?.label.toUpperCase()}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                  {[1, 3, 9].includes(x.t_type) && '-'}
                                  {fMoney.format(x.amount, { symbol: '₹ ' })}
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell sx={{ textAlign: 'center' }} colSpan="4">
                              Not Found!
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell sx={{ textAlign: 'center' }} colSpan="3">
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                              TOTAL
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'right' }} colSpan="3">
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                              {totalAmount.toFixed(2)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} />
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} px={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ backgroundColor: '#F3F6F8', padding: '5px' }}
                  >
                    <Typography variant="subtitle1">Sub Total</Typography>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                        <strong>{Math.round(allValues?.subtotal)?.toFixed(2)}</strong>
                      </Typography>
                    </Box>
                  </Stack>

                  <Box
                    sx={{
                      maxHeight: '100px',
                      padding: '5px',
                      overflow: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px', // Adjust width of the scrollbar here
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1', // Color of the track
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888', // Color of the scrollbar itself
                        borderRadius: '10px', // Round the edges of the scrollbar
                        border: '2px solid transparent', // Optional: adds border around the scrollbar
                        backgroundClip: 'content-box', // Ensures the border doesn't overlap the background
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', // Color of the scrollbar when hovered
                      },
                    }}
                  >
                    {cha_taxations?.fields
                      ?.filter((x) => x.order > 2 && !x?.accountlabel?.includes('GST') && x.accountId !== 56)
                      .map((field, index) => (
                        <Stack direction="row" justifyContent="space-between" key={index}>
                          <Typography variant="subtitle1">
                            {field.accountlabel || field.account?.name}
                          </Typography>
                          <Typography variant="subtitle1">
                            {field?.readOnly ? (
                              field?.amount
                            ) : (
                              <RHFTextField
                                key={index}
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                name={`cha_taxations.${allValues.in_type === 1 ? index + 4 : index + 3
                                  }.amount`}
                                onChange={(e) => {
                                  cha_taxations.update(
                                    allValues.in_type === 1 ? index + 4 : index + 3,
                                    {
                                      ...allValues.cha_taxations[
                                      allValues.in_type === 1 ? index + 4 : index + 3
                                      ],
                                      amount: parseFloat(e.target.value),
                                    }
                                  );
                                }}
                                value={
                                  allValues.cha_taxations[
                                    allValues.in_type === 1 ? index + 4 : index + 3
                                  ]?.amount
                                }
                                size="small"
                                type="number"
                                variant="standard"
                              />
                            )}
                          </Typography>
                        </Stack>
                      ))}
                  </Box>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ backgroundColor: '#F3F6F8', padding: '5px' }}
                  >
                    <Typography variant="subtitle1">Bill Amount</Typography>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                        <strong>{Math.round(allValues?.billamount)?.toFixed(2)}</strong>
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" sx={{ padding: '5px' }}>
                    <Typography variant="subtitle1">Balance</Typography>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle1">
                        <strong>{BillMainAMount - totalAmount}</strong>
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 2,
              position: 'fixed',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>

            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              {!partyData?.booking && (
                <Tooltip title="Add" arrow>
                  <IconButton color="primary" onClick={handleAdd}>
                    <Iconify icon="mingcute:add-line" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>
      {quickEditAccount && (
        <AUserQuickEditForm
          row={null || accountEditList}
          open={quickEditAccount.value}
          onClose={() => {
            quickEditAccount.onFalse();
            // partyAccountList();
          }}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </>
  );
}

ProductCategoryQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
