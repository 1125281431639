import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { handleScroll } from 'src/layouts/_common/scroller';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField, RHFUpload } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { JOBTEAYNO, KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { alpha } from '@mui/system';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import { HOST_API } from 'src/config-global';
import axios, { all } from 'axios';
import { useBgColorAutocomplete, filterCities, assignNullToEmptyOrUndefinedValues } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import { useResponsive } from 'src/hooks/use-responsive';
import { useDebounce } from 'src/hooks/use-debounce';
import { useGetCommon } from 'src/sections/work_shop/global';
import { useBoolean } from 'src/hooks/use-boolean';
import JobcardRedyQuickEditForm from './jobcard-redy-form';

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData, serviceTypesData, servicedealtrData, supervisorList, mechanicList, colourList
  , zoneDataList
  , yearData
  , sourcetypes
  , fuellevels
  , complaintCode, rempList, finalIList, jbcstatuses }) {
  const { enqueueSnackbar } = useSnackbar();

  const quickJBEdit = useBoolean();
  const [productValue, setProductValue] = useState('');


  const [cityValue, setCityValue] = useState({ key: '', search: '' });

  const searchCity = useDebounce(cityValue);
  const searchProduct = useDebounce(productValue);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const { user } = useContext(AuthContext);

  const lgUp = useResponsive('down', 'md');
  // Regular expression for Indian vehicle registration number validation
  const regNumberRegex = /^[A-Z]{2}\d{2}[A-Z]{0,2}\d{4}$/;
  const jobCardSchema = Yup.object().shape({
    regNo: Yup.string().nullable().matches(regNumberRegex, "Invalid registration number format"),
    identity: Yup.string().required('Job Card No is required'),
    customer: Yup.object().shape({
      firstname: Yup.string().required('First Name is required'),
      lastname: Yup.string().required('Surname is required'),
      mobile1: Yup.string()
        .required('Mobile number is required')
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    }),
  });

  const cyearId = user?.co_year?.id;

  const values = useMemo(
    () => ({
      cityMeta: {
        URL: 'cities/',
        query: {
          limit: 10,
          name: '',
          countryCode: 'IN',
        },
        editRow: null,
      },
      productMeta: {
        URL: 'automobile/products/',
        query: {
          status: true,
          amodcategoryId: '',
          category: 'VEHICLE',
          limit: 20,
          name: '',
        },
        editRow: null,
      },
      modelCategoryMeta: {
        URL: 'automobile/product/categories/',
        query: {
          limit: 50,
          name: '',
        },
        editRow: null,
      },
      co_yearId: row?.co_yearId || cyearId,
      identity: row?.identity || '',
      createdAt: row?.createdAt || new Date(),
      regNo: row?.regNo || '',
      chassisNo: row?.chassisNo || '',
      enginNo: row?.enginNo || '',
      batteryNo: row?.batteryNo || '',
      fscNo: row?.fscNo || '',
      productId: row?.productId || null,
      product: row?.product || '',
      accountId: row?.accountId || '',
      acolourId: row?.acolourId || null,
      amodcategoryId: row?.amodcategoryId || '',
      acolour: row?.acolour || '',
      veh_saledt_install: row?.veh_saledt_install || new Date(),
      expdelidate: row?.expdelidate || null,
      servicetypeId: row?.servicetypeId || '',
      fuellevelId: row?.fuellevelId || '',
      sourcetypeId: row?.sourcetypeId || '',
      currentkm: row?.currentkm || null,
      deliverykm: row?.deliverykm || null,
      customer: {
        firstname: row?.customer?.firstname || '',
        lastname: row?.customer?.lastname || '',
        mobile1: row?.customer?.mobile1 || '',
        mobile2: row?.customer?.mobile2 || '',
        email: row?.customer?.email || '',
        localityId: row?.customer?.localityId,
        locality: row?.customer?.locality?.name,
        cityList: row?.customer?.cityList || [],
        stateId: row?.customer?.stateId || null,
        city: row?.customer?.city || null,
        state: row?.customer?.state || null,
        cityId: row?.customer?.cityId || null,
        pincode: row?.customer?.pincode || null,
        address1: row?.customer?.address1 || '',
      },
      serviceaccountId: row?.serviceaccountId || null,
      sellingdelar: row?.sellingdelar || null,
      delaraccountId: row?.delaraccountId || null,
      servicedelar: row?.servicedelar || null,
      keyNo: row?.keyNo || '',
      supervisorId: row?.supervisorId || null,
      mechanicId: row?.mechanicId || null,
      images: row?.images || [],
      jobcomlinks:
        row?.jobcomlinks?.map((x) => ({
          complaintcodeId: x?.complaintcodeId,
          description: x?.description,
        })) || [],
      remark: row?.remark || '',
    }),
    [row, cyearId]
  );

  const methods = useForm({
    resolver: yupResolver(jobCardSchema),
    values,
  });

  const {
    reset,
    control,
    trigger,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = methods;

  const allValues = watch();

  const images = useFieldArray({
    control,
    name: 'images',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'jobcomlinks',
  });

  const onSubmit = handleSubmit(async (payload) => {
    try {
      assignNullToEmptyOrUndefinedValues(payload);
      let URL = '/automobile/jobcards/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const URL = `${HOST_API}upload`;

      try {
        await Promise.all(
          acceptedFiles.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);

            try {
              const response = await axios.post(URL, formData, {
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // setUploadProgress(percentCompleted); // Uncomment this to update progress if needed
                },
              });

              if (response?.data?.filename) {
                images.append(`${HOST_API}uploads/medium/${response.data.filename}`);
              }
            } catch (error) {
              enqueueSnackbar(`Upload failed for ${file.name}`, {
                variant: 'info',
              });
            }
          })
        );
      } catch (error) {
        enqueueSnackbar('Upload failed', {
          variant: 'error',
        });
      }
    },
    [images, enqueueSnackbar] // Add HOST_API here if it's not a constant
  );

  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = allValues.images && allValues.images?.filter((file) => file !== inputFile);
      setValue('images', filtered);
    },
    [setValue, allValues.images]
  );

  const modelCategory = useGetCommon(
    allValues.modelCategoryMeta
  );
  const debouncedProduct = useDebounce(allValues.productMeta?.query?.name, 500);
  const productList = useGetCommon(debouncedProduct ? allValues.productMeta : allValues.productMeta);
  const HandleCityList = useCallback(async () => {
    let URL = 'cities/';
    URL += `?page=${1}&limit=${100}&name=${searchCity.search}&status=true`;

    const { data } = await fetcher(URL);
    if (searchCity.key && searchCity.search) {
      setValue(searchCity.key, filterCities(data?.rows, searchCity.search));
    } else {
      setValue(searchCity.key, data?.rows);
    }
  }, [searchCity, setValue]);


  useEffect(() => {
    if (open) {
      HandleCityList();
    }
  }, [HandleCityList, open]);


  const CustomerFindJobcard = useCallback(
    async (typeValue) => {
      try {
        let URL = `automobile/customers/${typeValue}`;
        URL += `?page=${1}&limit=${10000}&status=true&type=chassisNo&`;
        const { data } = await fetcher(URL);
        if (data) {
          reset({
            ...allValues,
            chassisNo: data?.chassisNo,
            amodcategoryId: data?.product?.amodcategoryId || null,
            enginNo: data?.enginNo,
            batteryNo: data?.batteryNo,
            acolourId: data?.acolour?.id,
            acolour: data?.acolour?.name,
            productId: data?.product?.id,
            product: data?.product?.name,
            accountId: data?.account?.id,
            customer: {
              ...allValues?.customer,
              firstname: data?.customer?.firstname,
              lastname: data?.customer?.lastname,
              mobile1: data?.customer?.mobile1,
              mobile2: data?.customer?.mobile2,
              email: data?.customer?.email || '',
              cityId: data?.customer?.cityId,
              city: data?.customer?.city,
              localityId: data?.customer?.localityId,
              locality: data?.customer?.locality?.name,
              state: data?.customer?.state,
              stateId: data?.customer?.stateId,
              pincode: data?.customer?.pincode,
              address1: data?.customer?.address1,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset]
  );

  const jobcardSearchNumber = allValues?.chassisNo || allValues?.enginNo || allValues?.regNo;
  const typeValue = useDebounce(jobcardSearchNumber);

  useEffect(() => {
    if (typeValue && !row) {
      CustomerFindJobcard(typeValue);
    }
  }, [CustomerFindJobcard, typeValue, row]);



  useEffect(() => {
    if (allValues.amodcategoryId) {
      setValue('productMeta.query.amodcategoryId', allValues.amodcategoryId);
    }
  }, [allValues.amodcategoryId, setValue])

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleRemoveAllFiles = useCallback(() => {
    setValue('images', []);
  }, [setValue]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog fullScreen maxWidth={false} open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: lgUp
                ? 'primary.dark'
                : (theme) => alpha(theme.palette.background.default, 1),
              color: lgUp && 'white',
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Job Card ' : 'Create Job Card'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose} tabIndex="-1">
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 2 }} columnSpacing={8} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Typography variant="subtitle1" gutterBottom>
                Jobcard Details
              </Typography>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={10}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                alignContent="start"
                spacing={2}
              >
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    options={yearData.length ? yearData : [{ name: 'No Options', value: null }]}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => {
                      const startDate = new Date(option.sdate);
                      const endDate = new Date(option.edate);
                      return `${startDate.getFullYear()}-${endDate.getFullYear()}`;
                    }}
                    openOnFocus
                    onChange={(event, newValue) => {
                      setValue('co_yearId', newValue?.id);
                    }}
                    value={(() => {
                      const addRecord = yearData?.find((data) => data.id === allValues?.co_yearId);
                      const editRecord = row?.godown;

                      if (addRecord) return addRecord;
                      if (editRecord) return editRecord;
                      return null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Job Year/No."
                        name="co_yearId"
                        error={errors?.co_yearId && !allValues?.co_yearId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {`${new Date(option.sdate).getFullYear()}-${new Date(
                            option.edate
                          ).getFullYear()}`}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <RHFTextField
                    name="identity"
                    label="Job Card No."
                    size="small"
                    disabled={row?.id}
                    variant={lgUp ? 'standard' : 'outlined'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <Controller
                    name="createdAt"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        value={field.value ? new Date(field.value) : null}
                        timezone="Asia/Kolkata"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        label="Job Date"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                            variant: lgUp ? 'standard' : 'outlined',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="regNo"
                    label="Regi No."
                    size="small"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="chassisNo"
                    label="Chasis No."
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="enginNo"
                    label="Engine No."
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.7} lg={2.7} xl={2.7} xxl={2.7}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="batteryNo"
                    label="batteryNo"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.8} lg={2.8} xl={2.8} xxl={2.8}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="fscNo"
                    label="FSC No"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                alignContent="start"
                spacing={2}
              >
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <RHFSelect
                    name="amodcategoryId"
                    label="Model Category"
                    size="small"
                    variant={lgUp ? 'standard' : 'outlined'}
                    value={allValues.amodcategoryId || ''}
                  >
                    {modelCategory.rows.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={5} xl={5} xxl={5}>
                  <Autocomplete
                    autoHighlight={allValues.productMeta?.query?.name?.length > 0}
                    fullWidth
                    size="small"
                    options={productList.rows}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option?.name || ''}
                    filterOptions={(options, state) =>
                      productList.rows.length ? productList.rows : [{ name: 'No Options', id: null }]
                    }
                    inputValue={allValues?.productMeta?.query?.name || ''}
                    onInputChange={(event, newInputValue) => {
                      setValue('productMeta.query.name', newInputValue);

                    }}
                    openOnFocus
                    onChange={(event, newValue) => {
                      setValue('productId', newValue?.id || null);
                      setValue('product', newValue || null);
                      setValue('amodcategoryId', newValue?.amodcategoryId || null);
                    }}
                    value={allValues?.product || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Product"
                        name="productId"
                        error={errors?.productId && !allValues?.productId}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        key={option.id}
                        sx={{ minHeight: 'auto !important' }}
                      >
                        <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                      </Box>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                      onScroll: (event) =>
                        handleScroll({
                          event,
                          allValues,
                          setValue,
                          fieldName: 'productMeta',
                          meta: allValues.productMeta,
                          swr: productList,
                        }),
                    }}
                  />

                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={colourList?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={colourList?.find((data) => data.id === allValues.acolourId)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue('acolourId', colourList?.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="acolourId"
                        label="Select Colour"
                        error={errors?.acolourId && !allValues?.acolourId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <Controller
                    name="veh_saledt_install"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        value={field.value ? new Date(field.value) : null}
                        timezone="Asia/Kolkata"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        label="Veh SaleDt/Install"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                            variant: lgUp ? 'standard' : 'outlined',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={serviceTypesData?.map((option) => option?.name || '')}
                    getOptionLabel={(option) => option}
                    value={
                      serviceTypesData?.find((data) => data.id === allValues.servicetypeId)?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'servicetypeId',
                        serviceTypesData?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="servicetypeId"
                        label="Service Type"
                        error={errors?.servicetypeId && !allValues?.servicetypeId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: 300,
                        },
                      },
                    }}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="currentkm"
                    label="Kilometre"
                    size="small"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="deliverykm"
                    label="Delivery Kilometre"
                    size="small"
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Typography variant="subtitle1" gutterBottom>
                Customer Details
              </Typography>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              alignContent="start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFTextField
                  variant={lgUp ? 'standard' : 'outlined'}
                  name="customer.firstname"
                  label="First Name"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFTextField
                  variant={lgUp ? 'standard' : 'outlined'}
                  name="customer.lastname"
                  label="Last Name"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.1} lg={1.1} xl={1.1} xxl={1.1}>
                <RHFTextField
                  variant={lgUp ? 'standard' : 'outlined'}
                  name="customer.mobile1"
                  label="Mobile 1"
                  type="number"
                  size="small"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 10) setValue('customer.mobile1', e.target.value);
                  }}
                  error={errors?.customer?.mobile1 && allValues?.customer?.mobile1?.length !== 10}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.1} lg={1.1} xl={1.1} xxl={1.1}>
                <RHFTextField
                  variant={lgUp ? 'standard' : 'outlined'}
                  name="customer.mobile2"
                  label="Mobile 2"
                  type="number"
                  size="small"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 10) setValue('customer.mobile2', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
                <RHFTextField
                  variant={lgUp ? 'standard' : 'outlined'}
                  name="customer.email"
                  label="E-Mail"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.8} lg={1.8} xl={1.8} xxl={1.8}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  openOnFocus
                  options={zoneDataList?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={
                    zoneDataList?.find((data) => data.id === allValues?.customer?.localityId)
                      ?.name || ''
                  }
                  onChange={(event, newValue) => {
                    setValue(
                      'customer.localityId',
                      zoneDataList?.find((data) => data.name === newValue)?.id
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="customer.localityId"
                      label="Select Area"
                      error={errors?.customer?.localityId && !allValues?.customer?.localityId}
                      variant={lgUp ? 'standard' : 'outlined'}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{
                    ...bgColorAutocomplete,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.5} lg={1.5} xl={1.5} xxl={1.5}>
                <Autocomplete
                  autoHighlight={allValues.isHighligh}
                  fullWidth
                  size="small"
                  openOnFocus
                  onBlur={() => {
                    setValue('isHighligh', '');
                  }}
                  options={allValues.customer.cityList?.map((option) => option.name)}
                  value={
                    allValues.customer.cityList?.find((x) => x.id === allValues.customer.cityId)
                      ?.name ||
                    allValues?.customer?.city?.name ||
                    ''
                  }
                  onInputChange={(event, newInputValue) => {
                    const city = allValues.customer.cityList?.find((x) => x.name === newInputValue);
                    setValue('isHighligh', newInputValue);
                    if (newInputValue) {
                      setCityValue({ key: 'customer.cityList', search: newInputValue });
                      if (city) {
                        reset({
                          ...allValues,
                          customer: {
                            ...allValues.customer,
                            cityId: city.id,
                            stateId: city.state.id,
                            countryId: city.country.id,
                            state: city.state,
                            country: city.country,
                          },
                        });
                      }
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="customer.cityId"
                      label="Select City"
                      variant={lgUp ? 'standard' : 'outlined'}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={[allValues?.customer?.state]?.map((option) => option?.name)}
                  getOptionLabel={(option) => option}
                  value={allValues?.customer?.state?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="customer.stateId"
                      label="Select State"
                      error={errors?.stateId && !allValues?.customer?.stateId}
                      variant={lgUp ? 'standard' : 'outlined'}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}>
                <RHFTextField
                  variant={lgUp ? 'standard' : 'outlined'}
                  name="customer.pincode"
                  label="Pincode"
                  type="number"
                  value={allValues?.customer?.pincode || ''}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
                <RHFTextField
                  variant={lgUp ? 'standard' : 'outlined'}
                  name="customer.address1"
                  label="Addres"
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={6}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Others Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={servicedealtrData?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      allValues.sellingdelar?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'serviceaccountId',
                        servicedealtrData?.find((data) => data.name === newValue)?.id
                      );
                      setValue(
                        'servicedelar',
                        servicedealtrData?.find((data) => data.name === newValue)
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="serviceaccountId"
                        label="Service Dealer"
                        error={errors?.serviceaccountId && !allValues?.serviceaccountId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={servicedealtrData?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      allValues.sellingdelar?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'delaraccountId',
                        servicedealtrData?.find((data) => data.name === newValue)?.id
                      );
                      setValue(
                        'sellingdelar',
                        servicedealtrData?.find((data) => data.name === newValue)
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="delaraccountId"
                        label="Sold Dealer"
                        error={errors?.delaraccountId && !allValues?.delaraccountId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="keyNo"
                    label="Key No."
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={supervisorList?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      supervisorList?.find((data) => data.id === allValues.supervisorId)
                        ?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'supervisorId',
                        supervisorList?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="supervisorId"
                        label="Supervisor"
                        error={errors?.supervisorId && !allValues?.supervisorId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Controller
                    name="expdelidate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        value={field.value ? new Date(field.value) : null}
                        timezone="Asia/Kolkata"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        label="Delivery Date/Time"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                            variant: lgUp ? 'standard' : 'outlined',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={mechanicList?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      mechanicList?.find((data) => data.id === allValues.mechanicId)?.name ||
                      ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'mechanicId',
                        mechanicList?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="mechanicId"
                        label="Mechanic"
                        error={errors?.mechanicId && !allValues?.mechanicId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={sourcetypes?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={sourcetypes?.find((data) => data.id === allValues.sourcetypeId)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue('sourcetypeId', sourcetypes?.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="sourcetypeId"
                        label="Select Source types"
                        error={errors?.sourcetypeId && !allValues?.sourcetypeId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    options={fuellevels?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={fuellevels?.find((data) => data.id === allValues.fuellevelId)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue('fuellevelId', fuellevels?.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="fuellevelId"
                        label="Select Fuel levels"
                        error={errors?.fuellevelId && !allValues?.fuellevelId}
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoFocus
                    openOnFocus
                    autoHighlight
                    fullWidth
                    multiple
                    options={complaintCode}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={fields.map((field) =>
                      complaintCode.find((code) => code.id === field.complaintcodeId)
                    )}
                    onChange={(event, newValue) => {
                      remove(); // Remove all existing entries
                      newValue.forEach((value) => {
                        append({ complaintcodeId: value.id });
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select complaint code"
                        size="small"
                        variant={lgUp ? 'standard' : 'outlined'}
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.name}>
                        <Checkbox size="small" disableRipple checked={selected} />
                        {option.name}
                      </li>
                    )}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={option?.name}
                          label={option?.name}
                          size="small"
                        />
                      ))
                    }
                  />
                </Grid>
                {fields?.map((field, index) => (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} key={field.id}>
                    <Controller
                      control={control}
                      name={`jobcomlinks[${index}].description`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={`Description for ${complaintCode?.find(
                            (code) => code?.id === fields[index]?.complaintcodeId
                          )?.name
                            }`}
                          name={`jobcomlinks[${index}].description`}
                          variant="outlined"
                          size="small"
                          multiline
                          rows={2}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ))}

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField
                    variant={lgUp ? 'standard' : 'outlined'}
                    name="remark"
                    label="Remark"
                    size="small"
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2"> Upload Images</Typography>
                  <RHFUpload
                    multiple
                    thumbnail
                    name="images"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            justifyContent: 'space-between',
            bgcolor: lgUp ? 'primary.dark' : (theme) => alpha(theme.palette.background.default, 1),
            color: lgUp && 'white',
          }}
        >
          {!lgUp && (
            <Grid>
              <Button variant="outlined">Chassis History</Button>
              <Button variant="outlined" sx={{ marginLeft: '10px' }}>
                Job Card History
              </Button>
              <Button variant="outlined" sx={{ marginLeft: '10px' }} onClick={quickJBEdit.onTrue}>
                Job Card Ready
              </Button>
            </Grid>
          )}

          <Grid>
            <Button variant="outlined" onClick={onClose} sx={{ marginRight: '10px' }}>
              Cancel
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                bgcolor: !lgUp ? 'black' : (theme) => alpha(theme.palette.background.default, 1),
                color: lgUp && 'black',
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Grid>
        </DialogActions>
      </FormProvider>
      {quickJBEdit.value && (
        <JobcardRedyQuickEditForm
          colourList={colourList}
          zoneDataList={zoneDataList}
          yearData={yearData}
          sourcetypes={sourcetypes}
          fuellevels={fuellevels}
          complaintCode={complaintCode}
          serviceTypesData={serviceTypesData}
          jbcstatuses={jbcstatuses}
          mechanicList={mechanicList}
          finalIList={finalIList}
          supervisorList={supervisorList}
          rempList={rempList}
          row={row}
          open={quickJBEdit.value}
          onClose={quickJBEdit.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </Dialog>

  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  serviceTypesData: PropTypes.array,
  servicedealtrData: PropTypes.array,
  supervisorList: PropTypes.array,
  mechanicList: PropTypes.array,
  colourList: PropTypes.array,
  zoneDataList: PropTypes.array,
  yearData: PropTypes.array,
  sourcetypes: PropTypes.array,
  fuellevels: PropTypes.array,
  complaintCode: PropTypes.array,
  rempList: PropTypes.array,
  finalIList: PropTypes.array,
  jbcstatuses: PropTypes.array,
};
