import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import queryString from 'query-string';
// utils
import { fToNow } from 'src/utils/format-time';
// _mock
import { _contacts } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogContent, Divider, Grid, LinearProgress } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import { AuthContext } from 'src/auth/context/jwt';
import Importerrors from 'src/layouts/_common/importerrors';
import { KEY } from 'src/_mock/constant';
import { useEventListener } from 'src/hooks/use-event-listener';
import { enqueueSnackbar } from 'notistack';
import { create } from 'src/utils/axios';
import { isNaN } from 'lodash';

// ----------------------------------------------------------------------

export default function ImportPopover({ quickImport }) {
  const [importStatus, setImportStatus] = useState([
    // {
    //   id: -1,
    //   name: "VEHICAL OPENING STOCK",
    //   alt: "entries",
    //   url: 'entries',
    //   where: { t_type: 'op', pcategoryId: 3 },
    //   payload: { t_type: 'op', pcategoryId: 3 }
    // },
    // {
    //   id: -2,
    //   name: "VEHICAL BRANCH TRANSFER",
    //   alt: "entries",
    //   url: 'entries',
    //   where: { t_type: 'bt', pcategoryId: 3 },
    //   payload: { t_type: 'bt', pcategoryId: 3 }
    // },
    // {
    //   id: 121,
    //   name: "zones",
    //   alt: "zones",
    //   url: 'zones',
    //   fullurl: 'zones',
    // },
    // {
    //   id: 112,
    //   name: "categories",
    //   alt: "categories",
    //   url: 'product/categories',
    // },
    // {
    //   id: 122,
    //   name: "colours",
    //   alt: "colours",
    //   url: 'product/colours',
    // },
    // {
    //   id: 11,
    //   name: "users",
    //   alt: "users",
    //   url: 'users',
    //   fullurl: 'users'
    // },
    // {
    //   id: 12,
    //   name: "Complaints",
    //   alt: "complaintcodes",
    //   url: 'complaintcodes'
    // },
    // {
    //   id: 1,
    //   name: "Products",
    //   alt: "products",
    //   url: 'products'
    // },
    {
      id: 2,
      name: "Enquiry",
      alt: "aenquiries",
      url: 'enquiries'
    },
    {
      id: 3,
      name: "Account",
      alt: "accounts",
      url: 'accounts'
    },
    {
      id: 4,
      name: "Booking",
      alt: "bookings",
      url: 'bookings'
    },
    {
      id: 5,
      name: "Vehical Purchase",
      alt: "entries",
      url: 'entries',
      where: { t_type: 8, pcategoryId: 3 },
      payload: { t_type: 8, pcategoryId: 3 }
    },
    {
      id: 6,
      name: "Accessories Purchase",
      alt: "entries",
      url: 'entries',
      where: { t_type: 8, pcategoryId: 5 },
      payload: { t_type: 8, pcategoryId: 5 }
    },
    {
      id: 7,
      name: "Sales Department Other (Purchase and sale)",
      alt: "entries",
      url: 'entries',
      where: { t_type: 8, pcategoryId: 8 },
      payload: { t_type: 8, pcategoryId: 8 }
    },
    {
      id: 8,
      name: "Challans",
      alt: "challans",
      url: 'challans',
    },
    {
      id: 9,
      name: "Vehical Sale",
      alt: "entries",
      url: 'entries',
      where: { t_type: 9, pcategoryId: 3 },
      payload: { t_type: 9, pcategoryId: 3 }
    },
    {
      id: 10,
      name: "Accessories sale",
      alt: "entries",
      url: 'entries',
      where: { t_type: 9, pcategoryId: 5 },
      payload: { t_type: 9, pcategoryId: 5 }
    },
    {
      id: 11,
      name: "Sale notes",
      alt: "entries",
      url: 'entries',
      where: { t_type: '10,11' },
      payload: { t_type: '10,11' }
    },
    {
      id: 12,
      name: "JV,JE",
      alt: "entries",
      url: 'entries',
      where: { t_type: '7,13' },
      payload: { t_type: '7,13' }
    },
    {
      id: 13,
      name: "showroom and Workshop Cash & Bank R/P",
      alt: "entries",
      url: 'entries',
      where: { t_type: '1,2,3,4' },
      queryparmas: { isvoucher: true }
    },
    {
      id: 12,
      name: "jobcards",
      alt: "jobcards",
      url: 'jobcards'
    },
    {
      id: 14,
      name: "issues",
      alt: "issues",
      url: 'issues'
    },
    {
      id: 15,
      name: "jobcard invoice",
      alt: "entries",
      url: 'entries',
      where: { jobcardinvoice: 'true' },
      payload: { jobcardinvoice: 'true' }
    },
    {
      id: 16,
      name: "Disbrusment",
      alt: "entries",
      url: 'entries',
      where: { t_type: 12 },
      queryparmas: { disbrusment: true }
    },
    // {
    //   id: 17,
    //   name: "spare return invoice",
    //   alt: "entries",
    //   url: 'entries',
    //   where: { jobcardinvoice: 'false' },
    //   payload: { jobcardinvoice: 'false' }
    // }
  ]);
  const currentSyncIndex = useRef(0);

  const importAll = async (list) => {
    try {

      let URL = ''
      if (list.url) {
        URL = `automobile/${list.url}/importsync/`
      }
      if (list.fullurl) {
        URL = `/${list.fullurl}/importsync/`
      }
      let payload = { id: 1 }
      if (list?.payload) {
        payload = { ...payload, ...list.payload }
      }
      if (list?.queryparmas) {
        URL += `?${queryString.stringify(list.queryparmas)}`;
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      // eslint-disable-next-line no-await-in-loop
      const response = await create({ url: URL, payload, config });
    } catch (error) {
      console.log(error);
    }
  };

  const { user, socket } = useContext(AuthContext);
  const onImportError = useBoolean();
  const [progress, setProgress] = useState(importStatus.map(x => ({ id: x.id, name: x.name, where: x.where, status: 'pending', current: 0, total: 0 })));
  const so = useCallback(() => {
    socket?.on('progress', (data) => {
      console.log(data)
      const findDataIndex = importStatus.findIndex((item) => {
        const dataKeys = Object.keys(data);
        const isAltMatched = dataKeys.includes(item.alt);

        // Check if 'where' exists and if all its conditions match
        const isWhereMatched = !item.where || Object.keys(item.where).every(
          (key) => data[item.alt]?.where[key] === item.where[key]
        );

        return isAltMatched && isWhereMatched;
      });

      if (findDataIndex >= 0 && importStatus[findDataIndex].alt in data) {
        const currentImportData = data[importStatus[findDataIndex].alt];

        if (currentImportData.status === 'error') {
          progress[findDataIndex] = { ...progress[findDataIndex], ...currentImportData };
          setProgress(pre => [...pre]);
          onImportError.setValue(true);
        }

        if (currentImportData.status === 'done') {
          progress[findDataIndex].status = 'done';
          progress[findDataIndex].current = 100
          progress[findDataIndex].total = 100;

          // Call importAll for the next item only if the current one is done
          if (currentSyncIndex.current < importStatus.length - 1 && findDataIndex >= currentSyncIndex.current) {
            if (data[importStatus[currentSyncIndex.current].alt]?.status === 'done') {
              currentSyncIndex.current += 1;
              importAll(importStatus[currentSyncIndex.current]);
            }
          }

          setProgress(pre => [...pre]);
        } else if (currentImportData.current > 0) {
          progress[findDataIndex] = { ...progress[findDataIndex], ...currentImportData };
          setProgress(pre => [...pre]);
        }
      }
    });
  }, [importStatus, onImportError, progress, socket]);

  useEffect(() => so(), [so, socket])

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={quickImport.value ? 'inherit' : 'default'}
        onClick={quickImport.onTrue}
        sx={{
          ...(quickImport.value && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Iconify icon="solar:import-bold" width={24} />
      </IconButton>
      <Dialog fullScreen sx={{ p: 2 }} open={quickImport.value} onClose={quickImport.onClose} >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          <Button onClick={() => importAll(importStatus[0])} >
            <Iconify icon="solar:refresh-linear" width={36} height={36} />
          </Button>
          <Button onClick={() => quickImport.onFalse()} >
            <Iconify icon="solar:close-square-linear" width={36} height={36} />
          </Button>
        </Typography>
        <DialogContent sx={{ py: 5, px: 3, display: 'flex' }}>
          <Scrollbar sx={{ height: '100%' }}>
            <Divider />
            <Grid container spacing={2} mt={1}>
              <Grid container item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} > <b>Module</b></Grid>
              <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} > <b>Progress</b></Grid>
              <Grid container item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} > <b>Percentange</b> </Grid>
              <Grid container item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} > <b>Status</b></Grid>
            </Grid>
            <Divider sx={{ paddingTop: 1, paddingBottom: 1 }} />
            <Grid container spacing={2} mt={1}>
              {progress.map((contact) => (
                <>
                  <Grid container item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} > {contact.name}</Grid>
                  <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} > <LinearProgress
                    color="primary"
                    value={isNaN(Math.round(((contact.current || 0) / (contact.total || 0)) * 100)) ? 0 : Math.round(((contact.current || 0) / (contact.total || 0)) * 100)}
                    variant="determinate"
                    sx={{ mb: 2, width: 1 }}
                  /></Grid>
                  <Grid container item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} >{`${isNaN(Math.round(((contact.current || 0) / (contact.total || 0)) * 100)) ? 0 : Math.round(((contact.current || 0) / (contact.total || 0)) * 100)}%`} </Grid>
                  <Grid container item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} > {contact.status}</Grid></>
              ))}
            </Grid>


          </Scrollbar>
        </DialogContent>
      </Dialog>
      {onImportError.value && progress.find(x => 'errors' in x && Array.isArray(x.errors) && x.errors.length) && (
        <Importerrors
          row={progress.find(x => 'errors' in x && Array.isArray(x.errors) && x.errors.length)}
          open={onImportError.value}
          onClose={onImportError.onFalse}
        />
      )}
    </>
  );
}
ImportPopover.propTypes = {
  quickImport: PropTypes.object,
};